import { createApi } from '@reduxjs/toolkit/query/react'
import { extendedBasseQuery } from './BaseQuery'

export const mnemonicsAPI = createApi({
  reducerPath: 'mnemonicsAPI',
  baseQuery: extendedBasseQuery,
  tagTypes: ['Mnemonic'],
  /**
   * Defines the endpoints for the Mnemonics API.
   *
   * @param {object} builder - The API builder object.
   * @returns {object} The API endpoints.
   */
  endpoints: builder => ({
    mnemonics: builder.query({
      /**
       * Retrieves all mnemonics.
       *
       * @returns {string} The API query for retrieving all mnemonics.
       * @tags Mnemonic, Auth
       */
      query: () => '/template/mnemonics',
      providesTags: ['Mnemonic', 'Auth']
    }),
    mnemonic: builder.query({
      /**
       * Retrieves a mnemonic by ID.
       *
       * @param {string} id - The ID of the mnemonic to retrieve.
       * @returns {string} The API query for retrieving a mnemonic by ID.
       * @tags Mnemonic
       */
      query: id => `/template/mnemonics/${id}`,
      providesTags: ['Mnemonic']
    }),
    addMnemonic: builder.mutation({
      /**
       * Adds a new mnemonic.
       *
       * @param {object} field - The mnemonic data to add.
       * @returns {object} The API mutation query for adding a new mnemonic.
       * @invalidates Mnemonic
       */
      query: field => ({
        url: '/template/mnemonics',
        method: 'POST',
        body: field
      }),
      invalidatesTags: ['Mnemonic']
    }),
    addBulkMnemonics: builder.mutation({
      /**
       * Creates a query object with the specified body.
       *
       * @param {Object} body - The body of the query.
       * @return {Object} - The query object.
       */
      query: body => ({
        url: '/template/mnemonics/createBulkMnemonics',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Mnemonic']
    }),
    updateMnemonic: builder.mutation({
      /**
       * Updates a mnemonic by ID.
       *
       * @param {object} params - The parameters for updating the mnemonic.
       * @param {string} params.id - The ID of the mnemonic to update.
       * @param {object} params.rest - The updated mnemonic data.
       * @returns {object} The API mutation query for updating a mnemonic.
       * @invalidates Mnemonic
       */
      query: ({ id, ...rest }) => ({
        url: `/template/mnemonics/${id}`,
        method: 'PATCH',
        body: rest
      }),
      invalidatesTags: ['Mnemonic']
    }),
    deleteMnemonic: builder.mutation({
      /**
       * Deletes a mnemonic by ID.
       *
       * @param {string} id - The ID of the mnemonic to delete.
       * @returns {object} The API mutation query for deleting a mnemonic.
       * @invalidates Mnemonic
       */
      query: id => ({
        url: `/template/mnemonics/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Mnemonic']
    })
  })
})

export const {
  useMnemonicsQuery,
  useMnemonicQuery,
  useAddMnemonicMutation,
  useUpdateMnemonicMutation,
  useDeleteMnemonicMutation,
  useAddBulkMnemonicsMutation
} = mnemonicsAPI
