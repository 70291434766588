import { createSlice } from "@reduxjs/toolkit";
import { modulesAPI } from "../Services/Module";

const ModuleSlice = createSlice({
  name: "modules",
  initialState: { modules: [], phases: [] },
  reducers: {
    setModules: (state, { payload }) => {
      state.modules = payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      modulesAPI.endpoints.modules.matchFulfilled,
      (state, { payload }) => {
        state.modules = payload?.data;
      }
    );
    builder.addMatcher(
      modulesAPI.endpoints.phasesAndModules.matchFulfilled,
      (state, { payload }) => {
        state.phases = payload?.data;
      }
    );
  },
});

export const { setModules } = ModuleSlice.actions;

export default ModuleSlice.reducer;
