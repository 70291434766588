import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const checklistAPI = createApi({
  reducerPath: "checklistAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Checklist"],
  /**
   * Generates the endpoints for the given builder object.
   *
   * @param {object} builder - The builder object.
   * @return {object} The endpoints object.
   */
  endpoints: (builder) => ({
    checklists: builder.query({
      /**
       * Fetches the checklists
       * @return {string}
       * @tags Checklist
       */
      query: ({ type }) => `/playbook/checklist`,
      providesTags: ["Checklist"],
    }),
    checklist: builder.query({
      /**
       * Fetches a single checklist by its id.
       * @param {string} id - The ID of the checklist to retrieve.
       * @return {string}
       * @tags Checklist
       */
      query: (id) => `/playbook/checklist/${id}`,
      providesTags: ["Checklist"],
    }),
    addChecklist: builder.mutation({
      /**
       * Creates a new checklist.
       * @return {object}
       * @invalidates Checklist
       */
      query: (data) => ({
        url: "/playbook/checklist",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Checklist"],
    }),
    updateChecklist: builder.mutation({
      /**
       * Updates a checklist by ID.
       * @param {object} query - An object containing the id and additional properties.
       * @param {string} query.id - The id of the playbook to trigger.
       * @param {object} query.rest - Additional properties for the query.
       * @return {object}
       * @invalidates Checklist
       */
      query: ({ id, ...rest }) => ({
        url: `/playbook/checklist/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Checklist"],
    }),
    deleteChecklist: builder.mutation({
      /**
       * Deletes a checklist by ID.
       * @param {string} id - The ID of the checklist to delete.
       * @return {object}
       * @invalidates Checklist
       */
      query: (id) => ({
        url: `/playbook/checklist/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Checklist"],
    }),
  }),
});

export const {
  useChecklistsQuery,
  useChecklistQuery,
  useAddChecklistMutation,
  useUpdateChecklistMutation,
  useDeleteChecklistMutation,
} = checklistAPI;
