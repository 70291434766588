import { authAPI } from "../Services/Auth";
import { configureStore } from "@reduxjs/toolkit";
import { fieldsAPI } from "../Services/Field";
import { uploadAPI } from "../Services/Upload";
import authSlice, { authSliceMiddleware } from "../Reducers/AuthSlice";
import { sectionsAPI } from "../Services/Sections";
import { bitsAPI } from "../Services/Bits";
import { toolsAPI } from "../Services/Tools";
import { rigUpsAPI } from "../Services/RigUp";
import toastSlice from "../Reducers/ToastSlice";
import { mnemonicsAPI } from "../Services/Mnemonics";
import { alertsAPI } from "../Services/Alerts";
import { triggerTypesAPI } from "../Services/TriggerType";
import { logicalOperatorsAPI } from "../Services/LogicalOperators";
import { optimizationsAPI } from "../Services/Optimization";
import { usersAPI } from "../Services/Users";
import { jobsAPI } from "../Services/Jobs";
import { wellDetailsAPI } from "../Services/WellDetails";
import { sectionDetailsAPI } from "../Services/SectionDetails";
import settingsTabSlice from "../Reducers/settingsTabSlice";
import templateTabSlice from "../Reducers/templateTabSlice";
import procedureSlice from "../Reducers/procedureSlice";
import { proceduresAPI } from "../Services/Procedure";
import reportingSlice from "../Reducers/reportingSlice";
import { reportingAPI } from "../Services/Reporting";
import createButtonSlice from "../Reducers/createButtonSlice";
import moduleSlice from "../Reducers/moduleSlice";
import { modulesAPI } from "../Services/Module";
import jobSlice from "../Reducers/jobSlice";
import chatSlice from "../Reducers/chatSlice";
import { bhaAPI } from "../Services/Bha";
import { historyAPI } from "../Services/History";
import { phaseAPI } from "../Services/Phase";
import { chatAPI } from "../Services/Chat";
import { forgotPasswordAPI } from "../Services/AuthPass";
import notificationSlice from "../Reducers/NotificationSlice";
import { checklistAPI } from "../Services/Checklist";
import checklistSlice from "../Reducers/checklistSlice";
import {extractChecklistAPI} from "../Services/ChecklistExtract";
import BHADetailsSlice from "../Reducers/BHADetailsSlice";
import AccordionSlice from "../Reducers/AccordionSlice";
import {kpiAPI} from "../Services/Kpi";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [authAPI.reducerPath]: authAPI.reducer,
    authSlice,
    toastSlice,
    [fieldsAPI.reducerPath]: fieldsAPI.reducer,
    [sectionsAPI.reducerPath]: sectionsAPI.reducer,
    [bitsAPI.reducerPath]: bitsAPI.reducer,
    [toolsAPI.reducerPath]: toolsAPI.reducer,
    [rigUpsAPI.reducerPath]: rigUpsAPI.reducer,
    [mnemonicsAPI.reducerPath]: mnemonicsAPI.reducer,
    [alertsAPI.reducerPath]: alertsAPI.reducer,
    [triggerTypesAPI.reducerPath]: triggerTypesAPI.reducer,
    [logicalOperatorsAPI.reducerPath]: logicalOperatorsAPI.reducer,
    [optimizationsAPI.reducerPath]: optimizationsAPI.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [jobsAPI.reducerPath]: jobsAPI.reducer,
    [uploadAPI.reducerPath]: uploadAPI.reducer,
    [wellDetailsAPI.reducerPath]: wellDetailsAPI.reducer,
    [sectionDetailsAPI.reducerPath]: sectionDetailsAPI.reducer,
    [proceduresAPI.reducerPath]: proceduresAPI.reducer,
    [reportingAPI.reducerPath]: reportingAPI.reducer,
    [modulesAPI.reducerPath]: modulesAPI.reducer,
    [bhaAPI.reducerPath]: bhaAPI.reducer,
    [historyAPI.reducerPath]: historyAPI.reducer,
    [phaseAPI.reducerPath]: phaseAPI.reducer,
    [chatAPI.reducerPath]: chatAPI.reducer,
    [forgotPasswordAPI.reducerPath]: forgotPasswordAPI.reducer,
    [checklistAPI.reducerPath]: checklistAPI.reducer,
    [extractChecklistAPI.reducerPath]: extractChecklistAPI.reducer,
    [kpiAPI.reducerPath]: kpiAPI.reducer,
    settingsTabSlice,
    templateTabSlice,
    procedureSlice,
    reportingSlice,
    createButtonSlice,
    moduleSlice,
    jobSlice,
    chatSlice,
    notificationSlice,
    checklistSlice,
    BHADetailsSlice,
    AccordionSlice
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authSliceMiddleware,
      authAPI.middleware,
      fieldsAPI.middleware,
      sectionsAPI.middleware,
      bitsAPI.middleware,
      toolsAPI.middleware,
      rigUpsAPI.middleware,
      mnemonicsAPI.middleware,
      alertsAPI.middleware,
      triggerTypesAPI.middleware,
      logicalOperatorsAPI.middleware,
      optimizationsAPI.middleware,
      usersAPI.middleware,
      jobsAPI.middleware,
      uploadAPI.middleware,
      wellDetailsAPI.middleware,
      sectionDetailsAPI.middleware,
      proceduresAPI.middleware,
      reportingAPI.middleware,
      modulesAPI.middleware,
      bhaAPI.middleware,
      historyAPI.middleware,
      phaseAPI.middleware,
      chatAPI.middleware,
      forgotPasswordAPI.middleware,
      checklistAPI.middleware,
      extractChecklistAPI.middleware,
      kpiAPI.middleware
    ),
});
