import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const optimizationsAPI = createApi({
  reducerPath: "optimizationsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["DrillingOptimization"],
  endpoints: (builder) => ({
    drillingOptimizations: builder.query({
      query: () => "/playbook/drillingOptimization",
      providesTags: ["DrillingOptimization"],
    }),
    drillingOptimization: builder.query({
      query: (id) => `/playbook/drillingOptimization/${id}`,
      providesTags: ["DrillingOptimization"],
    }),
    addDrillingOptimization: builder.mutation({
      query: (field) => ({
        url: "/playbook/drillingOptimization",
        method: "POST",
        body: field,
      }),
      invalidatesTags: ["DrillingOptimization"],
    }),
    updateDrillingOptimization: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/playbook/drillingOptimization/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["DrillingOptimization"],
    }),
    deleteDrillingOptimization: builder.mutation({
      query: (id) => ({
        url: `/playbook/drillingOptimization/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DrillingOptimization"],
    }),
  }),
});

export const {
  useDrillingOptimizationsQuery,
  useDrillingOptimizationQuery,
  useAddDrillingOptimizationMutation,
  useUpdateDrillingOptimizationMutation,
  useDeleteDrillingOptimizationMutation,
} = optimizationsAPI;
