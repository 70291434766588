import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const reportingAPI = createApi({
  reducerPath: "reportingAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Reporting"],
  endpoints: (builder) => ({
    reportings: builder.query({
      query: () => "/playbook/reporting",
      providesTags: ["Reporting"],
    }),
    reporting: builder.query({
      query: (id) => `/playbook/reporting/${id}`,
      providesTags: ["Reporting"],
    }),
    addReporting: builder.mutation({
      query: (data) => ({
        url: "/playbook/reporting",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Reporting"],
    }),
    updateReporting: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/playbook/reporting/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Reporting"],
    }),
    deleteReporting: builder.mutation({
      query: (id) => ({
        url: `/playbook/reporting/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Reporting"],
    }),
  }),
});

export const {
  useReportingsQuery,
  useReportingQuery,
  useAddReportingMutation,
  useUpdateReportingMutation,
  useDeleteReportingMutation,
} = reportingAPI;
