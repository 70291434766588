import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const rigUpsAPI = createApi({
  reducerPath: "rigUpsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["RigUp"],
  /**
   * Generates the endpoints for Rigup Equipments API.
   *
   * @param {object} builder - The builder object for creating queries and mutations.
   * @return {object} The endpoints object containing the rigUps, rigUp, addRigUp,
   * updateRigUp, and deleteRigUp functions.
   */
  endpoints: (builder) => ({
    rigUps: builder.query({
      /**
       * Retrieves all rigUps.
       *
       * @returns {string} The API query for retrieving all rigUps.
       * @tags RigUp
       */
      query: () => "/template/rigUpEquipment",
      providesTags: ["RigUp"],
    }),
    rigUp: builder.query({
      /**
       * Retrieves a rigUp by ID.
       * @param {string} id - The ID of the rigUp to retrieve.
       * @returns {string} The API query for retrieving a rigUp.
       * @tags RigUp
       * */
      query: (id) => `/template/rigUpEquipment/${id}`,
      providesTags: ["RigUp"],
    }),
    addRigUp: builder.mutation({
      /**
       * Adds a new rigUp.
       * @param {object} rigup - The rigUp data to add.
       * @returns {object} The API mutation query for adding a new rigUp.
       * @invalidates RigUp
       * */
      query: (rigup) => ({
        url: "/template/rigUpEquipment",
        method: "POST",
        body: rigup,
      }),
      invalidatesTags: ["RigUp"],
    }),
    updateRigUp: builder.mutation({
      /**
       * Updates a rigUp by ID.
       * @param {object} params - The parameters for updating the rigUp.
       * @param {string} params.id - The ID of the rigUp to update.
       * @param {object} params.rest - The updated rigUp data.
       * @returns {object} The API mutation query for updating a rigUp.
       * @invalidates RigUp
       * */
      query: ({ id, ...rest }) => ({
        url: `/template/rigUpEquipment/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["RigUp"],
    }),
    deleteRigUp: builder.mutation({
      /**
       * Deletes a rigUp by ID.
       * @param {string} id - The ID of the rigUp to delete.
       * @returns {object} The API mutation query for deleting a rigUp.
       * @invalidates RigUp
       * */
      query: (id) => ({
        url: `/template/rigUpEquipment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RigUp"],
    }),
  }),
});

export const {
  useRigUpsQuery,
  useRigUpQuery,
  useAddRigUpMutation,
  useUpdateRigUpMutation,
  useDeleteRigUpMutation,
} = rigUpsAPI;
