import { createApi } from '@reduxjs/toolkit/query/react'
import { extendedBasseQuery } from './BaseQuery'

export const bhaAPI = createApi({
  reducerPath: 'bhaAPI',
  baseQuery: extendedBasseQuery,
  tagTypes: ['Bha'],
  endpoints: builder => ({
    addBha: builder.mutation({
      query: job => ({
        url: '/job/bha',
        method: 'POST',
        body: job
      }),
      invalidatesTags: ['Bha']
    }),
    addBulkBha: builder.mutation({
      query: job => ({
        url: '/job/bha/bulkCreate',
        method: 'POST',
        body: job
      }),
      invalidatesTags: ['Bha']
    }),
    updateBulkBha: builder.mutation({
      query: job => ({
        url: '/job/bha/bulkUpdate',
        method: 'PATCH',
        body: job
      }),
      invalidatesTags: ['Bha']
    }),
    updateBha: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/job/bha/${id}`,
        method: 'PATCH',
        body: rest
      }),
      invalidatesTags: ['Bha']
    }),
    deleteBha: builder.mutation({
      query: id => ({
        url: `/job/bha/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Bha']
    })
  })
})

export const {
  useAddBhaMutation,
  useAddBulkBhaMutation,
  useUpdateBulkBhaMutation,
  useUpdateBhaMutation,
  useDeleteBhaMutation
} = bhaAPI
