import React from 'react'
import { Alert, Grid, IconButton, Snackbar, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenNotification } from '../Reducers/NotificationSlice'
import { BlockIcon, SmallCloseIcon } from './CustomIcon'
import { useLocation } from 'react-router-dom'

const CustomNotification = ({
  severity,
  message,
  vertical,
  horizontal,
  ...props
}) => {
  const { open } = useSelector(state => state.notificationSlice)
  const dispatch = useDispatch()
  const location = useLocation()

  const alertColor = {
    warning: {
      color: '#BDA730',
      backgroundColor: '#FFF2C2'
    },
    error: {
      color: '#F52B19',
      backgroundColor: '#FEEBEB'
    }
  }

  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{ vertical, horizontal }}
      sx={{
        marginBottom: '90px',
        marginRight: '26px',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        maxWidth: '422px',
        width: '422px',
        '& .MuiPaper-root': {
          padding: '6px 16px 6px 10px'
        }
      }}
      open={
        location.pathname === '/dashboard' || location.pathname === '/chat'
          ? false
          : open
      }
      onClose={() => dispatch(setOpenNotification({ open: false }))}
    >
      <Alert
        severity={severity}
        variant='filled'
        sx={{
          color: alertColor[severity].color,
          backgroundColor: alertColor[severity].backgroundColor,
          alignItems: 'center',
          fontSize: '20px',
          width: '100%',
          '& .MuiAlert-message': {
            width: '100%'
          }
        }}
        {...props}
        icon={false}
      >
        <Grid container direction='row' justifyContent={'space-between'}>
          <Grid
            item
            lg={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <BlockIcon
              style={{
                height: '22px',
                width: '22px',
                color: alertColor[severity].color
              }}
            />
          </Grid>
          <Grid item lg={9} paddingLeft={'5px'}>
            <Typography style={{ fontSize: '20px', width: '100%' }}>
              <Typography style={{ fontSize: '20px', fontWeight: 700 }}>
                Attention!
              </Typography>
              {message}
            </Typography>
          </Grid>
          <Grid
            item
            lg={1}
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'flex-start'}
          >
            <IconButton
              aria-label='close'
              color='inherit'
              sx={{ p: 0.5 }}
              onClick={() => dispatch(setOpenNotification({ open: false }))}
            >
              <SmallCloseIcon
                style={{
                  height: '14px',
                  width: '14px',
                  fill: alertColor[severity].color
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Alert>
    </Snackbar>
  )
}

export default CustomNotification
