import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import CustomButton from '../../components/CustomButton'
import robotImg from '../../assets/images/robot-image.svg'
import RovaLogo from '../../assets/images/rova-final-logo.svg'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useLoginMutation } from '../../Services/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { setToast } from '../../Reducers/ToastSlice'
import LoginInput from '../../components/Auth/LoginInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginValidation } from './validations/login.validation'
import ForgotPasswordDialog from '../../components/Auth/ForgotPasswordDialog'

const useStyles = makeStyles({
  loginRoot: {
    backgroundImage: `url(${robotImg}) !important`,
    backgroundSize: 'cover !important',
    backgroundPosition: 'center !imprtant'
  },
  customInput: {
    '& .MuiInputBase-input': {
      padding: 0,
      fontFamily: 'satoshi-bold'
    },
    '& .MuiInputLabel-root': {
      textTransform: 'uppercase',
      fontFamily: 'satoshi-medium',
      fontSize: '0.8rem',
      opacity: '0.65',
      color: 'rgba(0, 0, 0, 0.5)',
      '&:after': {
        fontFamily: 'satoshi-medium'
      }
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      borderBottom: '1px solid rgba(172, 187, 199, 0.5);',
      padding: '15px 12px 10px',
      paddingLeft: '0px !important',
      fontFamily: 'satoshi-medium',
      '&:hover': {},
      '&:focus-within': {
        borderBottom: `1px solid rgba(172, 187, 199, 0.5);`
      },
      '&:after': {
        border: '0px '
      },
      '&:before': {
        border: '0px !important',
        content: "''"
      }
    },
    '& .MuiInput-underline:after': {
      border: '1px solid #CED4DA',
      borderBottomColor: 'transparent'
    },
    '& .MuiInput-underline:before': {
      border: '1px solid #CED4DA',
      borderBottomColor: 'transparent'
    }
  }
})

const LogIn = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const auth = useSelector(state => state.authSlice)

  const [login, { isLoading }] = useLoginMutation()
  const [forgotPassword, setForgotPassword] = useState(false)
  const [afterForgotPassword, setAfterForgotPassword] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
    setFocus
  } = useForm({
    resolver: yupResolver(loginValidation)
  })

  useEffect(() => {
    setFocus('email')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async data => {
    const loggingIn = await login(data)
    if ('data' in loggingIn) {
      dispatch(
        setToast({
          severity: 'success',
          message: loggingIn?.data?.message,
          open: true
        })
      )
      if (loggingIn?.data?.data?.flag) {
        navigate('/dashboard', { replace: true })
      } else {
        navigate('/resetPassword/', { replace: true })
      }
    }
  }

  if (auth?.user && !auth?.user?.flag) {
    return <Navigate to='/resetPassword' state={{ from: '/login' }} replace />
  }

  if (auth?.user && auth?.user?.flag) {
    return <Navigate to='/dashboard' state={{ from: '/login' }} replace />
  }

  return (
    <Container
      component='div'
      style={{
        minHeight: '100vh',
        maxWidth: '100%',
        margin: 0,
        padding: 0,
        backgroundColor: '#ffffff !important'
      }}
    >
      <Grid
        container
        spacing={0}
        alignItems='center'
        sx={{ backgroundColor: '#ffffff !important' }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              paddingLeft: '188px',
              width: '80%',
              backgroundColor: '#ffffff !important'
            }}
          >
            <Box noValidate sx={{ width: '95%' }}>
              <img alt='ROVA' src={RovaLogo} width='40px' />
            </Box>
            <Box noValidate sx={{ mt: 1, width: '95%' }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <LoginInput
                  register={register}
                  name='email'
                  label='Email'
                  errors={errors}
                />
                <LoginInput
                  register={register}
                  name='password'
                  type='password'
                  label='Password'
                  errors={errors}
                  info={
                    afterForgotPassword &&
                    'A temporary password has been sent to your email address'
                  }
                />
                <CustomButton
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{
                    mt: '60px',
                    mb: '13px',
                    width: '273px',
                    height: '65px',
                    fontFamily: 'satoshi-black',
                    fontSize: '20px',
                    textTransform: 'none'
                  }}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Login
                </CustomButton>
                {!afterForgotPassword && (
                  <Typography
                    variant='body1'
                    sx={{
                      fontFamily: 'satoshi-regular',
                      fontSize: '14px',
                      color: '#95999C'
                    }}
                  >
                    <Link
                      to='#'
                      style={{
                        fontSize: '18px',
                        color: '#95999C',
                        fontWeight: 'bold',
                        textDecoration: 'none'
                      }}
                      replace
                      onClick={() => setForgotPassword(true)}
                    >
                      Forgot Password?
                    </Link>
                  </Typography>
                )}
              </form>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={6}
          className={classes.loginRoot}
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            padding: '0px',
            paddingLeft: '10%',
            color: '#242A31',
            boxShadow: '0px 0px 4px 0px #B5B7BF',
          }}
        >
          <Box sx={{ width: '560px', padding: 0 }}>
            <Typography
              sx={{
                fontFamily: 'satoshi-medium',
                fontSize: '20px'
              }}
            >
              WELCOME TO
            </Typography>
            <Typography
              variant='h4'
              sx={{
                fontSize: '60px',
                fontFamily: 'satoshi-black',
                marginTop: '10px'
              }}
            >
              Remote Operations {'\n'} Virtual Assistant
            </Typography>
            <Divider
              sx={{
                mt: 7,
                mb: 4,
                mr: 3,
                backgroundColor: 'black',
                width: '50%'
              }}
            />
            <Typography
              sx={{
                fontFamily: 'satoshi-bold',
                fontSize: '22px'
              }}
            >
              Login to access Dashboard
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {forgotPassword && (
        <ForgotPasswordDialog
          open={forgotPassword}
          handleClose={() => setForgotPassword(false)}
          handleSuccess={setAfterForgotPassword}
          title='Forgot Password'
        />
      )}
    </Container>
  )
}

export default LogIn
