import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeIndex: 0,
  allCollapsed: false,
  reviewExpandedIndex: -1,
  rigDetails: {
    display: true,
    expanded: true,
    done: false,
    index: 0,
    updatedAt: ''
  },
  wellDetails: {
    display: true,
    expanded: false,
    done: false,
    index: 1,
    updatedAt: ''
  },
  sectionAndBHA: {
    display: false,
    expanded: false,
    done: false,
    index: 2,
    updatedAt: ''
  },
  BHADetails: [],
  rigupChecklist: {
    display: false,
    expanded: false,
    done: false,
    index: 3,
    updatedAt: ''
  },
  kpis: {
    display: false,
    expanded: false,
    done: false,
    index: 4,
    updatedAt: ''
  }
}

const accordionSlice = createSlice({
  name: 'accordion',
  initialState: initialState,
  reducers: {
    /**
     * Sets the state of the accordion based on the provided action.
     *
     * @param {object} state - The current state of the accordion.
     * @param {object} action - The action object containing the payload.
     */
    setAccordionState: (state, action) => {
      state = { ...state, ...action.payload }
    },
    resetAccordionState: (state, action) => {
      // console.log('reset accordion state')
      state.activeIndex = 0
      state.allCollapsed = false
      state.reviewExpandedIndex = action.payload?.reviewExpandedIndex
      state.rigDetails = {
        display: true,
        expanded: true,
        done: false,
        index: 0,
        updatedAt: ''
      }
      state.wellDetails = {
        display: true,
        expanded: false,
        done: false,
        index: 1,
        updatedAt: ''
      }
      state.sectionAndBHA = {
        display: false,
        expanded: false,
        done: false,
        index: 2,
        updatedAt: ''
      }
      state.BHADetails = []
      state.rigupChecklist = {
        display: false,
        expanded: false,
        done: false,
        index: 3,
        updatedAt: ''
      }
      state.kpis = {
        display: false,
        expanded: false,
        done: false,
        index: 4,
        updatedAt: ''
      }
    },
    /**
     * Sets the active index of the accordion.
     *
     * @param {object} state - The current state object.
     * @param {object} action - The action object containing the payload.
     * @param {number} action.payload - The index of the active accordion.
     * @return {void}
     */
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload
    },
    /**
     * Sets the review expanded index of the accordion.
     * @param {object} state - The current state object.
     * @param {object} action - The action object containing the payload.
     * @param {number} action.payload - The index of the active accordion.
     * @return {void}
     * */
    setReviewExpandedIndex: (state, action) => {
      state.reviewExpandedIndex = action.payload
    },
    /**
     * Sets the done state of a given item in the state object.
     *
     * @param {Object} state - The state object.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The payload of the action.
     * @return {void}
     */
    setDoneState: (state, action) => {
      state[action.payload].done = true
    },
    /**
     * Sets the done state of a given item in the state object.
     *
     * @param {Object} state - The state object.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The payload of the action.
     * @return {void}
     */
    setBHADoneState: (state, action) => {
      state.BHADetails[action.payload].done = true
    },
    /**
     * Sets the "done" property of the BHADetails object at the specified index to false.
     *
     * @param {Object} state - The current state object.
     * @param {Object} action - The action object containing the payload.
     * @param {number} action.payload - The index of the BHADetails object.
     */
    setBHAUndoneState: (state, action) => {
      state.BHADetails[action.payload].done = false
    },
    /**
     * Sets the expanded state of a given item in the state object.
     *
     * @param {Object} state - The state object.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The payload of the action.
     * @return {void}
     */
    setExpandedState: (state, action) => {
      state[action.payload].expanded = true
      state.activeIndex = state[action.payload].index
      Object.keys(state).forEach(key => {
        if (
          key !== 'activeIndex' &&
          key !== 'BHADetails' &&
          key !== 'reviewExpandedIndex' &&
          key !== 'allCollapsed' &&
          key !== action.payload &&
          state[key].expanded === true
        ) {
          state[key].expanded = false
        }
      })
      state.BHADetails.forEach(bha => {
        bha.expanded = false
      })
    },
    setToggleExpandedState: (state, action) => {
      state[action.payload].expanded = !state[action.payload].expanded
      state.allCollapsed = !state[action.payload].expanded
      state.activeIndex = state[action.payload].index
      Object.keys(state).forEach(key => {
        if (
          key !== 'activeIndex' &&
          key !== 'BHADetails' &&
          key !== 'reviewExpandedIndex' &&
          key !== 'allCollapsed' &&
          key !== action.payload &&
          state[key].expanded === true
        ) {
          state[key].expanded = false
        }
      })
      state.BHADetails.forEach(bha => {
        bha.expanded = false
      })
    },
    /**
     * Sets the expanded state of a specific element in the state object.
     *
     * @param {Object} state - The state object.
     * @param {Object} action - The action object.
     * @param {number} action.payload - The index of the element to be expanded.
     * @return {void}
     */
    setExpandedByIndex: (state, action) => {
      // console.log('setExpandedByIndex', action.payload)
      Object.keys(state).forEach(key => {
        if (
          key !== 'activeIndex' &&
          key !== 'BHADetails' &&
          key !== 'reviewExpandedIndex' &&
          key !== 'allCollapsed'
        ) {
          if (state[key].index === action.payload) {
            state[key].expanded = true
            state.activeIndex = state[key].index
            state.BHADetails.forEach(bha => {
              bha.expanded = false
            })
          } else {
            state[key].expanded = false
          }
        }
      })
      state.BHADetails.forEach(bha => {
        if (bha.index === action.payload) {
          bha.expanded = true
          state.allCollapsed = !bha.expanded
          state.activeIndex = bha.index
        } else {
          bha.expanded = false
        }
      })
    },
    setToggleExpandedByIndex: (state, action) => {
      // console.log('setToggleExpandedByIndex', action.payload)
      Object.keys(state).forEach(key => {
        if (
          key !== 'activeIndex' &&
          key !== 'BHADetails' &&
          key !== 'reviewExpandedIndex' &&
          key !== 'allCollapsed'
        ) {
          if (state[key].index === action.payload) {
            state[key].expanded = !state[key].expanded
            state.activeIndex = state[key].index
            state.BHADetails.forEach(bha => {
              bha.expanded = false
            })
          } else {
            state[key].expanded = false
          }
        }
      })
      state.BHADetails.forEach(bha => {
        if (bha.index === action.payload) {
          bha.expanded = !bha.expanded
          state.allCollapsed = !bha.expanded
          state.activeIndex = bha.index
        } else {
          bha.expanded = false
        }
      })
    },
    /**
     * Sets the display state of a specific element in the state object.
     *
     * @param {Object} state - The current state object.
     * @param {Object} action - The action object containing the payload.
     * @param {string} action.payload - The key of the element to update.
     */
    setDisplayState: (state, action) => {
      state[action.payload].display = true
    },
    /**
     * Updates the state with the new BHADetails and adjusts the rigupChecklist and kpis indices.
     *
     * @param {object} state - The current state object.
     * @param {object} action - The action object containing the payload.
     * @return {void}
     */
    addBhas: (state, action) => {
      state.BHADetails = action.payload
      state.rigupChecklist.index = action.payload.length + 3
      state.kpis.index = action.payload.length + 4
    },
    /**
     * Updates the state of BHA details with the provided data.
     *
     * @param {object} state - The current state object.
     * @param {object} action - The action object containing the payload.
     * @param {string} action.payload.name - The name of the BHA detail.
     * @param {object} action.payload.data - The data to update the BHA detail with.
     * @return {void}
     */
    updateBHAState: (state, action) => {
      state.BHADetails[action.payload.name] = {
        ...state.BHADetails[action.payload.name],
        ...action.payload.data
      }
    },
    /**
     * Sets the updatedAt property of a specific state object.
     *
     * @param {object} state - The current state object.
     * @param {object} action - The action object containing the payload.
     * @param {string} action.payload.name - The name of the state object to update.
     * @param {string} action.payload.date - The new value for the updatedAt property.
     */
    setUpdatedAt: (state, action) => {
      state[action.payload.name].updatedAt = action.payload.date
    }
  }
})

export const {
  setAccordionState,
  resetAccordionState,
  setActiveIndex,
  setReviewExpandedIndex,
  setDoneState,
  setBHADoneState,
  setBHAUndoneState,
  setExpandedState,
  setToggleExpandedState,
  setExpandedByIndex,
  setToggleExpandedByIndex,
  setDisplayState,
  setUpdatedAt,
  updateBHAState,
  addBhas
} = accordionSlice.actions

export default accordionSlice.reducer
