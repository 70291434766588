import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const usersAPI = createApi({
  reducerPath: "usersAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    users: builder.query({
      query: () => "/users",
      providesTags: ["User"],
    }),
    teamLeaders: builder.query({
      query: (role) => `/users/teamLeader?role=${role}`,
      providesTags: ["User"],
    }),
    user: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: ["User"],
    }),
    addUserByAdmin: builder.mutation({
      query: (field) => ({
        url: "/users/byAdmin",
        method: "POST",
        body: field,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/users/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["User"],
    }),
    resetPassword: builder.mutation({
      query: (creds) => ({
        url: "/users/changeGeneratedPassword",
        method: "POST",
        body: creds,
      }),
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useUsersQuery,
  useTeamLeadersQuery,
  useUserQuery,
  useAddUserByAdminMutation,
  useUpdateUserMutation,
  useResetPasswordMutation,
  useDeleteUserMutation,
} = usersAPI;
