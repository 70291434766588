import { createSlice } from "@reduxjs/toolkit";

const createButtonSlice = createSlice({
  name: "createButton",
  initialState: {
    name: "",
    openField: false,
    openSection: false,
    openBit: false,
    openTool: false,
    openRigup: false,
    openMnemonic: false,
    openAlert: false,
    openOptimization: false,
    openProcedure: false,
    openChecklist: false,
    openReporting: false,
    openUser: false,
    openFunc: null,
    isEmptyTab: true,
    username: "",
  },
  reducers: {
    setButtonName: (state, { payload }) => {
      state.name = payload.name;
      state.openFunc = payload.openFunc;
    },
    openFieldForm: (state, { payload }) => {
      state.openField = payload.openField;
    },
    openSectionForm: (state, { payload }) => {
      state.openSection = payload.openSection;
    },
    openBitForm: (state, { payload }) => {
      state.openBit = payload.openBit;
    },
    openToolForm: (state, { payload }) => {
      state.openTool = payload.openTool;
    },
    openRigupForm: (state, { payload }) => {
      state.openRigup = payload.openRigup;
    },
    openMnemonicsForm: (state, { payload }) => {
      state.openMnemonic = payload.openMnemonic;
    },
    openAlertForm: (state, { payload }) => {
      state.openAlert = payload.openAlert;
    },
    openOptimizationForm: (state, { payload }) => {
      state.openOptimization = payload.openOptimization;
    },
    openProcedureForm: (state, { payload }) => {
      state.openProcedure = payload.openProcedure;
    },
    openChecklistForm: (state, { payload }) => {
      state.openChecklist = payload.openChecklist;
    },
    openReportingForm: (state, { payload }) => {
      state.openReporting = payload.openReporting;
    },
    openUserForm: (state, { payload }) => {
      state.openUser = payload.openUser;
    },
    setUsername: (state, { payload }) => {
      state.username = payload.username;
    },
    setEmptyTab: (state, { payload }) => {
      state.isEmptyTab = payload.isEmptyTab;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setButtonName,
  openFieldForm,
  openSectionForm,
  openBitForm,
  openToolForm,
  openRigupForm,
  openMnemonicsForm,
  openAlertForm,
  openOptimizationForm,
  openProcedureForm,
  openChecklistForm,
  openReportingForm,
  openUserForm,
  setUsername,
  setEmptyTab,
} = createButtonSlice.actions;

export default createButtonSlice.reducer;
