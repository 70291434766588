import { createSlice } from '@reduxjs/toolkit'

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    severity: null,
    message: null,
    open: false,
    notifications: [],
    totalUnreadCount: 0,
    totalUnreadAlertCount: 0
  },
  reducers: {
    setNotificationToast: (state, { payload: { severity, message, open,location } }) => {
      state.severity = severity
      state.message = message
      state.open = open
    },
    setOpenNotification: (state, { payload: { open } }) => {
      state.open = open
    },
    addNotification: (state, { payload: { notification } }) => {
      state.notifications.push(notification)
    },
    setNotification: (state, { payload: { notifications } }) => {
      state.notifications = [...notifications, ...state.notifications]
      // filter out unique notifications
      state.notifications = state?.notifications?.filter(
        (notification, index, self) =>
          index === self.findIndex(t => t._id === notification._id)
      )
      
      state.notifications.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
    },
    setTotalUnreadCount: (state, { payload: { totalUnreadCount } }) => {
      state.totalUnreadCount = totalUnreadCount
    },
    setTotalUnreadAlertCount: (
      state,
      { payload: { totalUnreadAlertCount } }
    ) => {
      state.totalUnreadAlertCount = totalUnreadAlertCount
    }
  },
  extraReducers: builder => {}
})

export const {
  setNotificationToast,
  setOpenNotification,
  addNotification,
  setNotification,
  setTotalUnreadCount,
  setTotalUnreadAlertCount
} = notificationSlice.actions

export default notificationSlice.reducer
