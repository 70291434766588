import { createSlice } from "@reduxjs/toolkit";
import { proceduresAPI } from "../Services/Procedure";

const ProcedureSlice = createSlice({
  name: "procedure",
  initialState: { procedures: [] },
  reducers: {
    setProcuders: (state, { payload }) => {
      state.procedures = payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      proceduresAPI.endpoints.procedures.matchFulfilled,
      (state, { payload }) => {
        state.procedures = payload?.data;
      }
    );
  },
});

export const { setProcuders } = ProcedureSlice.actions;

export default ProcedureSlice.reducer;
