import * as yup from "yup";

const passPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

export const forgotResetPasswordValidation = yup.object().shape({
  newPassword: yup
    .string()
    .required("New password is required")
    .matches(
      passPattern,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "New Password & Confirm Password do not match"),
});
