import { FormHelperText } from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";

/**
 * Renders the error message for a form.
 *
 * @param {Object} error - The error object containing the error message.
 * @return {ReactNode} The rendered error message.
*/
const useStyles = makeStyles((theme) => ({
  errorText: {
    marginLeft: 0,
    color: "#F52B19!important",
    fontSize: "18px!important",
    fontFamily: "satoshi-medium!important",
  },
}));
const FormErrorText = ({ error }) => {
  const classes = useStyles();

  return (
    <FormHelperText sx={{ marginLeft: 0}} className={classes.errorText}>{error.message}</FormHelperText>
  );
};

export default FormErrorText;
