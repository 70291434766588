import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const sectionsAPI = createApi({
  reducerPath: "sectionsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Section"],
  /**
   * Generates an object containing various endpoints for the builder.
   *
   * @param {Object} builder - the builder object
   * @return {Object} - an object with sections, section, addSection, and updateSection endpoints
   */
  endpoints: (builder) => ({
    sections: builder.query({
      /**
       * Fetches the sections list.
       * @returns {string}
       */
      query: () => "/template/holeSection",
      providesTags: ["Section"],
    }),
    section: builder.query({
      /**
       * Generate the query string for retrieving hole sections by reservoir type.
       *
       * @param {string} reservoirType - The type of reservoir.
       * @return {string} The query string.
       */
      query: (reservoirType) =>
        `/template/holeSection`,
      providesTags: ["Section"],
    }),
    addSection: builder.mutation({
      /**
       * Create a new section.
       *
       * @param {any} section - The section to be used in the query.
       * @return {object} The query object with url, method, and body properties.
       */
      query: (section) => ({
        url: "/template/holeSection",
        method: "POST",
        body: section,
      }),
      invalidatesTags: ["Section"],
    }),
    updateSection: builder.mutation({
      /**
       * Update an existing section by id.
       *
       * @param {Object} id - The ID of the template hole section to update.
       * @param {Object} rest - Additional properties to include in the request body.
       * @return {Object} - The generated query object.
       */
      query: ({ id, ...rest }) => ({
        url: `/template/holeSection/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Section"],
    }),
  }),
});

export const {
  useSectionsQuery,
  useSectionQuery,
  useAddSectionMutation,
  useUpdateSectionMutation,
} = sectionsAPI;
