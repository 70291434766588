import React from 'react'

const SettingsPage = React.lazy(() => import('./Settings'))
const JobsComponent = React.lazy(() => import('./Jobs'))
const JobForm = React.lazy(() => import('./Jobs/CreateJob'))
const EditJob = React.lazy(() => import('./Jobs/EditJob'))
const ReviewJob = React.lazy(() => import('./Jobs/ReviewJob'))
const DashboardPage = React.lazy(() => import('./Dashboard'))
const RovaChat = React.lazy(() => import('./Chat'))

/**
 * Routes for the application by user role
 */
export const routes = {
  DEVELOPER: [
    {
      path: '/dashboard',
      exact: true,
      component: DashboardPage
    },
    {
      path: 'settings',
      exact: true,
      component: SettingsPage
    },
    {
      path: 'jobs',
      exact: true,
      component: JobsComponent
    },
    {
      path: 'jobs/createJob',
      component: JobForm
    },
    {
      path: 'jobs/edit/:id',
      component: EditJob
    },
    {
      path: 'jobs/reviewJob',
      component: ReviewJob
    },
    {
      path: 'chat',
      component: RovaChat
    }
  ],
  ROADMIN: [
    {
      path: '/dashboard',
      exact: true,
      component: DashboardPage
    },
    {
      path: 'settings',
      exact: true,
      component: SettingsPage
    },
    {
      path: 'jobs',
      exact: true,
      component: JobsComponent
    },
    {
      path: 'jobs/createJob',
      component: JobForm
    },
    {
      path: 'jobs/edit/:id',
      component: EditJob
    },
    {
      path: 'jobs/reviewJob',
      component: ReviewJob
    },
    {
      path: 'chat',
      component: RovaChat
    }
  ],
  ADVANCED: [
    {
      path: '/dashboard',
      exact: true,
      component: DashboardPage
    },
    {
      path: 'settings',
      exact: true,
      component: SettingsPage
    },
    {
      path: 'jobs',
      exact: true,
      component: JobsComponent
    },
    {
      path: 'jobs/createJob',
      component: JobForm
    },
    {
      path: 'jobs/edit/:id',
      component: EditJob
    },
    {
      path: 'jobs/reviewJob',
      component: ReviewJob
    },
    {
      path: 'chat',
      component: RovaChat
    }
  ],
  TEAMLEADER: [
    {
      path: '/dashboard',
      exact: true,
      component: DashboardPage
    },
    {
      path: 'settings',
      exact: true,
      component: SettingsPage
    },
    {
      path: 'jobs',
      exact: true,
      component: JobsComponent
    },
    {
      path: 'jobs/createJob',
      component: JobForm
    },
    {
      path: 'jobs/edit/:id',
      component: EditJob
    },
    {
      path: 'jobs/reviewJob',
      component: ReviewJob
    },
    {
      path: 'chat',
      component: RovaChat
    }
  ],
  RO: [
    {
      path: '/dashboard',
      exact: true,
      component: DashboardPage
    },
    {
      path: 'jobs',
      exact: true,
      component: JobsComponent
    },
    {
      path: 'jobs/createJob',
      component: JobForm
    },
    {
      path: 'jobs/edit/:id',
      component: EditJob
    },
    {
      path: 'jobs/reviewJob',
      component: ReviewJob
    },
    {
      path: 'chat',
      component: RovaChat
    }
  ]
}
