import React from 'react'
import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { setOpen } from '../Reducers/ToastSlice'
import { useDispatch, useSelector } from 'react-redux'

const CustomToast = ({ severity, message, vertical, horizontal, ...props }) => {
  const { open } = useSelector(state => state.toastSlice)
  const dispatch = useDispatch()

  return (
    <Snackbar
      autoHideDuration={2000}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={() => dispatch(setOpen({ open: false }))}
    >
      <Alert
        severity={severity}
        variant='filled'
        sx={{
          width: '100%',
          color: 'white',
          alignItems: 'center',
          fontSize: '20px'
        }}
        {...props}
      >
        <AlertTitle style={{ textTransform: 'capitalize', fontSize: '20px' }}>
          {severity}
        </AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default CustomToast
