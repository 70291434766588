import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const toolsAPI = createApi({
  reducerPath: "toolsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Tool"],
  /**
   * Generates the endpoints for the given builder.
   *
   * @param {object} builder - The builder object.
   * @return {object} The generated endpoints.
   */
  endpoints: (builder) => ({
    tools: builder.query({
      /**
       * Fetch all tools data.
       *
       * @param {string} filter - The filter to be applied to the query string.
       * @return {string} - The generated query string.
       * @tags Tool
       */
      query: (filter) =>
        filter ? `/template/partnerTools?${filter}` : "/template/partnerTools",
      providesTags: ["Tool"],
    }),
    tool: builder.query({
      /**
       * Fetches a single tool by its id.
       * @param {string} id - The ID of the tool to retrieve.
       * @returns {string} - The generated query string.
       * @tags Tool
       * */
      query: (id) => `/template/partnerTools/${id}`,
      providesTags: ["Tool"],
    }),
    addTool: builder.mutation({
      /**
       * Creates a new tool.
       * @param {object} tool - The tool data to add.
       * @return {object} - The generated mutation query.
       * @invalidates Tool
       * */
      query: (tool) => ({
        url: "/template/partnerTools",
        method: "POST",
        body: tool,
      }),
      invalidatesTags: ["Tool"],
    }),
    updateTool: builder.mutation({
      /**
       * Updates a tool by ID.
       * @param {object} params - The parameters for updating the tool.
       * @param {string} params.id - The ID of the tool to update.
       * @param {object} params.rest - The updated tool data.
       * @return {object} - The generated mutation query.
       * @invalidates Tool
       * */
      query: ({ id, ...rest }) => ({
        url: `/template/partnerTools/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Tool"],
    }),
    deleteTool: builder.mutation({
      /**
       * Deletes a tool by ID.
       * @param {string} id - The ID of the tool to delete.
       * @return {object} - The generated mutation query.
       * @invalidates Tool
       * */
      query: (id) => ({
        url: `/template/partnerTools/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tool"],
    }),
  }),
});

export const {
  useToolsQuery,
  useToolQuery,
  useAddToolMutation,
  useUpdateToolMutation,
  useDeleteToolMutation,
} = toolsAPI;
