import { createSlice } from "@reduxjs/toolkit";
import { reportingAPI } from "../Services/Reporting";

const ReportingSlice = createSlice({
  name: "reporting",
  initialState: { reportings: [] },
  reducers: {
    setProcuders: (state, { payload }) => {
      state.reportings = payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      reportingAPI.endpoints.reportings.matchFulfilled,
      (state, { payload }) => {
        state.reportings = payload?.data;
      }
    );
  },
});

export const { setProcuders } = ReportingSlice.actions;

export default ReportingSlice.reducer;
