import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const uploadAPI = createApi({
  reducerPath: "uploadAPI",
  baseQuery: extendedBasseQuery,
  //   tagTypes: ["Upload"],
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (data) => ({
        url: "/upload",
        method: "POST",
        body: data,
      }),
      //   invalidatesTags: ["Upload"],
    }),
  }),
});

export const { useUploadFileMutation } = uploadAPI;
