import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const logicalOperatorsAPI = createApi({
  reducerPath: "logicalOperatorsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["LogicalOperators"],
  /**
   * Generates the endpoints for the given builder.
   *
   * @param {Function} builder - The builder object.
   * @returns {Object} - The generated endpoints.
   */
  endpoints: (builder) => ({
    logicalOperators: builder.query({
      /**
       * fetches the logical operators
       * @returns {string}
       * @tags LogicalOperators
       * */
      query: () => "/meta/logicalOperator",
      providesTags: ["LogicalOperators"],
    }),
  }),
});

export const { useLogicalOperatorsQuery } = logicalOperatorsAPI;
