import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const alertsAPI = createApi({
  reducerPath: "alertsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Alert"],
  /**
   * Generate the endpoints for the builder.
   *
   * @param {object} builder - The builder object.
   * @return {object} The generated endpoints.
   */
  endpoints: (builder) => ({
    alerts: builder.query({
      /**
       * Fetch all trigegrs data
       * @return {string} - The generated query string.
       * */
      query: () => "/playbook/alert",
      providesTags: ["Alert"],
    }),
    alert: builder.query({
      /**
       * Fetches a single alert by its id.
       * @param {string} id - The ID of the alert to retrieve.
       * @return {string} - The generated query string.
       * @tags Alert
       * */
      query: (id) => `/playbook/alert/${id}`,
      providesTags: ["Alert"],
    }),
    addAlert: builder.mutation({
      /**
       * Creates a new alert from given data.
       *
       * @param {string} alert - The alert to be included in the query body.
       * @return {object} - The query object with the specified URL, method, and body.
       * @invalidates Alert
       */
      query: (alert) => ({
        url: "/playbook/alert",
        method: "POST",
        body: alert,
      }),
      invalidatesTags: ["Alert"],
    }),
    updateAlert: builder.mutation({
      /**
       * Updates a alert by ID.
       *
       * @param {object} query - An object containing the id and additional properties.
       * @param {string} query.id - The id of the playbook to alert.
       * @param {object} query.rest - Additional properties for the query.
       * @return {object} - The query object.
       */
      query: ({ id, ...rest }) => ({
        url: `/playbook/alert/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Alert"],
    }),
    deleteAlert: builder.mutation({
      /**
       * Deletes a alert by ID
       *
       * @param {type} id - the ID of the resource to be deleted
       * @return {object} an object containing the URL and method for the DELETE request
       */
      query: (id) => ({
        url: `/playbook/alert/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Alert"],
    }),
  }),
});

export const {
  useAlertsQuery,
  useAlertQuery,
  useAddAlertMutation,
  useUpdateAlertMutation,
  useDeleteAlertMutation,
} = alertsAPI;
