import { createSlice } from "@reduxjs/toolkit";

const templateTabInitialData = [
  {
    label: "Fields",
    disabled: false,
  },
  {
    label: "Sections",
    disabled: true,
  },
  {
    label: "Drill Bits",
    disabled: true,
  },
  {
    label: "Tools",
    disabled: true,
  },
  {
    label: "Rig up",
    disabled: true,
  },
  {
    label: "Parameters",
    disabled: true,
  },
];

const templateTabSlice = createSlice({
  name: "toast",
  initialState: { templateTabsData: [...templateTabInitialData] },
  reducers: {
    setTemplateTabsData: (state, { payload }) => {
      state.templateTabsData = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setTemplateTabsData } = templateTabSlice.actions;

export default templateTabSlice.reducer;
