import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormErrorText from "../Form/FormHelperText";

const useStyles = makeStyles({
  customInput: {
    "& .MuiInputBase-input": {
      padding: "15px 12px 15px 18px",
      borderRadius: "5px !important",
      fontFamily: "satoshi-bold",
    },
    "& .MuiInputLabel-root": {
      fontFamily: "satoshi-medium",
      fontSize: "0.8rem",
      opacity: "0.65",
      color: "rgba(0, 0, 0, 0.5)",
      "&:after": {
        fontFamily: "satoshi-medium",
      },
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ECEEF0",
      width: "544px",
      borderRadius: "5px !important",
      fontFamily: "satoshi-medium",
      fontSize: "20px",
      "&:hover": {
        backgroundColor: "#ECEEF0",
      },
      "&:focus-within": {},
      "&:after": {},
      "&:before": {
        content: "''",
      },
    },
    "& .MuiInput-underline:after": {
      border: "1px solid #CED4DA",
      borderBottomColor: "transparent",
    },
    "& .MuiInput-underline:before": {
      border: "1px solid #CED4DA",
      borderBottomColor: "transparent",
    },
  },
});

const LoginInput = ({
  register,
  name,
  label,
  type = "text",
  right,
  errors,
  info,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl
      sx={{
        padding: "0px !important",
        mt: "60px",
        width: "95%",
      }}
      className={classes.loginInput}
      error={errors[name] !== undefined}
    >
      <Typography
        variant="body1"
        sx={{
          fontFamily: "satoshi-medium",
          color: "#95999C",
          fontSize: "18px",
          transform: "none !important",
          marginBottom: "10px",
        }}
      >
        {label}
      </Typography>
      <TextField
        {...register(name)}
        variant="filled"
        fullWidth
        className={classes.customInput}
        type={type}
        InputProps={{
          disableUnderline: true,
        }}
        {...props}
      />
      {info && (
        <FormHelperText
          sx={{
            fontFamily: "satoshi-medium!important",
            fontSize: "18px",
            color: "#2E5BFF",
            margin: "10px 0px",
          }}
        >
          {info}
        </FormHelperText>
      )}
      {errors[name] !== undefined && <FormErrorText error={errors[name]} />}
    </FormControl>
  );
};

export default LoginInput;
