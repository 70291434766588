import { createApi } from '@reduxjs/toolkit/query/react'
import { extendedBasseQuery } from './BaseQuery'

export const jobsAPI = createApi({
  reducerPath: 'jobsAPI',
  baseQuery: extendedBasseQuery,
  tagTypes: ['Job'],
  /**
   * Generates the endpoints for the given builder.
   *
   * @param {object} builder - The builder object.
   * @return {object} The generated endpoints.
   */
  endpoints: builder => ({
    jobs: builder.query({
      /**
       * Generates a query string for the job endpoint based on the provided parameters.
       *
       * @param {Object} options - The options object containing the access, status, and phase parameters.
       * @param {string} options.access - The access parameter for the query string.
       * @param {string} [options.status] - The status parameter for the query string.
       * @param {string} [options.phase] - The phase parameter for the query string.
       * @returns {string} The generated query string.
       */
      query: ({ access, status, phase }) => {
        // status
        //   ? phase
        //   ? `/job?access=${access}&status=${status}&phase=${phase}`
        //   : `/job?access=${access}&status=${status}`
        // : `/job?access=${access}`,
        if (phase) {
          return `/job?access=${access}&phase=${phase}`
        } else if (status) {
          return `/job?access=${access}&status=${status}`
        } else {
          return `/job?access=${access}`
        }
      },
      providesTags: ['Job']
    }),
    job: builder.query({
      /**
       * Fetches a single job by its id.
       */
      query: id => `/job/job/${id}`,
      providesTags: ['Job']
    }),
    addJob: builder.mutation({
      /**
       * Generates a query object for a given job.
       *
       * @param {Object} job - The job object.
       * @return {Object} The query object.
       */
      query: job => ({
        url: '/job',
        method: 'POST',
        body: job
      }),
      invalidatesTags: ['Job']
    }),
    duplicateJob: builder.mutation({
      /**
       * Generates a query object for a job.
       *
       * @param {Object} job - The job object to be included in the query body.
       * @return {Object} - The query object with the URL, method, and job body.
       */
      query: job => ({
        url: '/job/duplicate',
        method: 'POST',
        body: job
      }),
      invalidatesTags: ['Job']
    }),
    handover: builder.mutation({
      /**
       * Generate a query object for the "handover" job endpoint.
       *
       * @param {Object} creds - The credentials object.
       * @return {Object} - The query object.
       */
      query: creds => ({
        url: '/job/handover',
        method: 'POST',
        body: creds
      }),
      invalidatesTags: ['Job']
    }),
    updateJob: builder.mutation({
      /**
       * Generate a query object for updating a job by ID.
       *
       * @param {Object} param - An object containing parameters for the query.
       * @param {string} param.id - The ID of the job to be updated.
       * @param {Object} param.rest - The remaining parameters for the query.
       * @return {Object} - The query object.
       */
      query: ({ id, ...rest }) => ({
        url: `/job/job/${id}`,
        method: 'PATCH',
        body: rest
      }),
      invalidatesTags: ['Job']
    }),
    deleteJob: builder.mutation({
      /**
       * Generate the function comment for the given function body.
       *
       * @param {type} id - The ID of the job to delete.
       * @return {object} An object containing the URL and method for the DELETE request.
       */
      query: id => ({
        url: `/job/job/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Job']
    })
  })
})

export const {
  useJobsQuery,
  useJobQuery,
  useAddJobMutation,
  useDuplicateJobMutation,
  useHandoverMutation,
  useUpdateJobMutation,
  useDeleteJobMutation
} = jobsAPI
