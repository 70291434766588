import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./AuthBaseQuery";

export const forgotPasswordAPI = createApi({
  baseQuery: baseQuery,
  tagTypes: ["UserForgotPassword"],
  endpoints: (builder) => ({
    forgotPass: builder.mutation({
      query: (email) => ({
        url: "/users/forgotPassword",
        method: "POST",
        body: email,
      }),
    }),
    forgotResetPass: builder.mutation({
      query: (data) => ({
        url: "/users/resetPassword",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useForgotPassMutation, useForgotResetPassMutation } =
  forgotPasswordAPI;
