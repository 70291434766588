import { createApi } from '@reduxjs/toolkit/query/react'
import { extendedBasseQuery } from './BaseQuery'

export const chatAPI = createApi({
  reducerPath: 'chatAPI',
  baseQuery: extendedBasseQuery,
  tagTypes: ['Chat'],
  endpoints: builder => ({
    messages: builder.query({
      query: ({ jobId, limit, offset }) =>
        `/chat/${jobId}?limit=${limit}&offset=${offset}`,
      providesTags: ['Chat']
    }),
    count: builder.query({
      query: ({ userId, jobId }) =>
        `/chat/getCount?id=${userId}&jobId=${jobId}`,
      providesTags: ['Chat']
    }),
    readCount: builder.mutation({
      query: ({ userId, jobId }) => ({
        url: `/chat/readMessage?jobId=${jobId}`,
        method: 'POST'
      }),

      invalidatesTags: ['Chat']
    }),
    createMessage: builder.mutation({
      query: body => ({
        url: `/chat/dashboard`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Chat']
    }),
    getChatList: builder.mutation({
      query: body => ({
        url: `/chat/get/chat`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Chat']
    }),
    getAlertsByUser: builder.query({
      query: ({ userId,messageType }) =>
        `/chat/getChat/byMessageType?${messageType ? `messageType=${messageType}&` : ''}userId=${userId}`,
      providesTags: ['Chat']
    })
  })
})

export const {
  useMessagesQuery,
  useCountQuery,
  useReadCountMutation,
  useCreateMessageMutation,
  useGetChatListMutation,
  useGetAlertsByUserQuery
} = chatAPI
