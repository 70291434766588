import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { loggedOut, setCredentials } from "../Reducers/AuthSlice";
import { setToast } from "../Reducers/ToastSlice";
import { Mutex } from "async-mutex";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  mode: "cors",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().authSlice.token;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const mutex = new Mutex();
export const extendedBasseQuery = async (args, api, extraOptions) => {
  try {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);

    if (result.error) {
      if (result.error.status === 401) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            const userData = api.getState().authSlice?.user;
            const token = api.getState().authSlice?.token;
            const formData = {
              id: userData?._id,
              token: token,
              refreshToken: userData?.refreshToken,
            };
            const refreshResult = await baseQuery(
              { url: "/auth/refreshToken", method: "POST", body: formData },
              api,
              extraOptions
            );
            if (refreshResult.data) {
              // store the new token
              await api.dispatch(
                setCredentials({
                  user: refreshResult?.data?.data,
                  token: refreshResult?.data?.data?.token,
                })
              );
              // retry the initial query
              result = await baseQuery(args, api, extraOptions);
            } else {
              localStorage.clear();
              api.dispatch(loggedOut());
            }
          } finally {
            release();
          }
        } else {
          await mutex.waitForUnlock();
          result = await baseQuery(args, api, extraOptions);
        }
      } else {
        api.dispatch(
          setToast({
            severity: "error",
            message: result?.error?.data?.message,
            open: true,
          })
        );
      }
    }

    if (result?.meta && result?.meta?.response?.status === 201) {
      api.dispatch(
        setToast({
          severity: "success",
          message: result?.data?.message,
          open: true,
        })
      );
    }

    return result;
  } catch (error) {
    api.dispatch(
      setToast({
        severity: "error",
        message: "Somthing went wrong",
        open: true,
      })
    );
    return;
  }
};

export const baseQueryFile = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  mode: "cors",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().authSlice.token;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      headers.set("Content-Type", "multipart/form-data");
    }

    return headers;
  },
});
