import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../components/CustomButton";
import robotImg from "../../assets/images/robot-image.svg";
import RovaLogo from "../../assets/images/rova-final-logo.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../Reducers/ToastSlice";
import LoginInput from "../../components/Auth/LoginInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordValidation } from "./validations/resetPassword.validation";
import { useResetPasswordMutation } from "../../Services/Users";
import { setCredentials } from "../../Reducers/AuthSlice";

const useStyles = makeStyles({
  loginRoot: {
    backgroundImage: `url(${robotImg}) !important`,
    backgroundSize: "cover !important",
    backgroundPosition: "center !imprtant",
  },
  customInput: {
    "& .MuiInputBase-input": {
      padding: 0,
      fontFamily: "satoshi-bold",
    },
    "& .MuiInputLabel-root": {
      textTransform: "uppercase",
      fontFamily: "satoshi-medium",
      fontSize: "0.8rem",
      opacity: "0.65",
      color: "rgba(0, 0, 0, 0.5)",
      "&:after": {
        fontFamily: "satoshi-medium",
      },
    },
    "& .MuiInputBase-root": {
      backgroundColor: "transparent",
      borderBottom: "1px solid rgba(172, 187, 199, 0.5);",
      padding: "15px 12px 10px",
      paddingLeft: "0px !important",
      fontFamily: "satoshi-medium",
      "&:hover": {},
      "&:focus-within": {
        borderBottom: `1px solid rgba(172, 187, 199, 0.5);`,
      },
      "&:after": {
        border: "0px ",
      },
      "&:before": {
        border: "0px !important",
        content: "''",
      },
    },
    "& .MuiInput-underline:after": {
      border: "1px solid #CED4DA",
      borderBottomColor: "transparent",
    },
    "& .MuiInput-underline:before": {
      border: "1px solid #CED4DA",
      borderBottomColor: "transparent",
    },
  },
});

const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.authSlice);

  const [reset, { isLoading }] = useResetPasswordMutation();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordValidation),
  });

  useEffect(() => {
    if (auth.user && auth?.user?.email) {
      setValue("email", auth?.user?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const onSubmit = async (data) => {
    delete data?.confirmPassword;

    const resetting = await reset(data);

    if ("data" in resetting) {
      dispatch(
        setCredentials({
          user: { ...auth.user, flag: true },
          token: auth.token,
        })
      );
      dispatch(
        setToast({
          severity: "success",
          message: resetting?.data?.message,
          open: true,
        })
      );
      navigate("/dashboard", { replace: true });
    }
  };

  if (auth.user && auth.user?.flag && auth.user.role === "ADMIN") {
    return <Navigate to="/settings" state={{ from: "/login" }} replace />;
  }

  if (auth.user && auth.user?.flag) {
    return <Navigate to="/dashboard" state={{ from: "/login" }} replace />;
  }

  return (
    <Container
      component="div"
      style={{
        minHeight: "100vh",
        maxWidth: "100%",
        margin: 0,
        padding: 0,
        backgroundColor: "#ffffff !important",
      }}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        sx={{ backgroundColor: "#ffffff !important" }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              paddingLeft: '188px',
              width: "100%",
              backgroundColor: "#ffffff !important",
            }}
          >
            <Box noValidate sx={{ width: "95%" }}>
              <img alt="ROVA" src={RovaLogo} width="40px" />
            </Box>
            <Box noValidate sx={{ mt: 1, width: "95%" }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <LoginInput
                  register={register}
                  name="oldPassword"
                  type="password"
                  label="Generated Password"
                  errors={errors}
                />
                <LoginInput
                  register={register}
                  name="newPassword"
                  type="password"
                  label="New Password"
                  errors={errors}
                />
                <LoginInput
                  register={register}
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  errors={errors}
                />
                <CustomButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    fontSize:'20px',
                    mt: "60px",
                    mb: 1,
                    width: "255px",
                    height: "55px",
                    fontFamily: 'satoshi-black',
                    textTransform: "none",
                  }}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Reset
                </CustomButton>
              </form>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={6}
          className={classes.loginRoot}
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            padding: "0px",
            paddingLeft: "10%",
            color: '#242A31',
            boxShadow: '0px 0px 4px 0px #B5B7BF',
          }}
        >
          <Box sx={{ width: '560px', padding: 0 }}>
            <Typography
              sx={{
                fontFamily: 'satoshi-medium',
                fontSize: '20px'
              }}
            >
              WELCOME TO
            </Typography>
            <Typography variant='h4' sx={{ fontSize: '60px', fontFamily:'satoshi-black', marginTop:'10px' }}>
              Remote Operations {'\n'} Virtual Assistant
            </Typography>
            <Divider
              sx={{
                mt: 7,
                mb: 4,
                mr: 3,
                backgroundColor: 'black',
                width: '50%'
              }}
            />
            <Typography
              sx={{
                fontFamily: 'satoshi-bold',
                fontSize: '22px'
              }}
            >
              Login to access Dashboard
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResetPassword;
