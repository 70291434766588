import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
  name: "toast",
  initialState: { severity: null, message: null, open: false },
  reducers: {
    setToast: (state, { payload: { severity, message, open } }) => {
      state.severity = severity;
      state.message = message;
      state.open = open;
    },
    setOpen: (state, { payload: { open } }) => {
      state.open = open;
    },
  },
  extraReducers: (builder) => {},
});

export const { setToast, setOpen } = toastSlice.actions;

export default toastSlice.reducer;
