import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import CustomButton from '../../components/CustomButton'
import robotImg from '../../assets/images/robot-image.svg'
import RovaLogo from '../../assets/images/rova-final-logo.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setToast } from '../../Reducers/ToastSlice'
import LoginInput from '../../components/Auth/LoginInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { forgotResetPasswordValidation } from './validations/forgotResetPassword.validation'
import axios from 'axios'

const useStyles = makeStyles({
  loginRoot: {
    backgroundImage: `url(${robotImg}) !important`,
    backgroundSize: 'cover !important',
    backgroundPosition: 'center !imprtant'
  },
  customInput: {
    '& .MuiInputBase-input': {
      padding: 0,
      fontFamily: 'satoshi-bold'
    },
    '& .MuiInputLabel-root': {
      textTransform: 'uppercase',
      fontFamily: 'satoshi-medium',
      fontSize: '0.8rem',
      opacity: '0.65',
      color: 'rgba(0, 0, 0, 0.5)',
      '&:after': {
        fontFamily: 'satoshi-medium'
      }
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      borderBottom: '1px solid rgba(172, 187, 199, 0.5);',
      padding: '15px 12px 10px',
      paddingLeft: '0px !important',
      fontFamily: 'satoshi-medium',
      '&:hover': {},
      '&:focus-within': {
        borderBottom: `1px solid rgba(172, 187, 199, 0.5);`
      },
      '&:after': {
        border: '0px '
      },
      '&:before': {
        border: '0px !important',
        content: "''"
      }
    },
    '& .MuiInput-underline:after': {
      border: '1px solid #CED4DA',
      borderBottomColor: 'transparent'
    },
    '& .MuiInput-underline:before': {
      border: '1px solid #CED4DA',
      borderBottomColor: 'transparent'
    }
  }
})

const BASE_URL = process.env.REACT_APP_API_URL

const ForgotResetPassword = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (id !== undefined) {
      localStorage.setItem('resetToken', id)
    }
  }, [id])

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(forgotResetPasswordValidation)
  })

  const onSubmit = async data => {
    try {
      const formData = {
        password: data?.newPassword
      }
      const headers = {
        headers: {
          Authorization: 'Bearer ' + id
        }
      }
      setIsLoading(true)
      const path = BASE_URL + `/users/resetPassword`
      const resetting = await axios.post(path, formData, headers)
      if (resetting?.data?.success) {
        dispatch(
          setToast({
            severity: 'success',
            message: resetting?.data?.message,
            open: true
          })
        )
        navigate('/login', { replace: true })
        setIsLoading(false)
      } else {
        setIsLoading(false)
        dispatch(
          setToast({
            severity: 'error',
            message: resetting?.data?.response,
            open: true
          })
        )
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Container
      component='div'
      style={{
        minHeight: '100vh',
        maxWidth: '100%',
        margin: 0,
        padding: 0,
        backgroundColor: '#ffffff !important'
      }}
    >
      <Grid
        container
        spacing={0}
        alignItems='center'
        sx={{ backgroundColor: '#ffffff !important' }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              paddingLeft: '188px',
              width: '100%',
              backgroundColor: '#ffffff !important'
            }}
          >
            <Box noValidate sx={{ width: '95%' }}>
              <img alt='ROVA' src={RovaLogo} width='40px' />
            </Box>
            <Box noValidate sx={{ mt: 1, width: '95%' }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <LoginInput
                  register={register}
                  name='newPassword'
                  type='password'
                  label='New Password'
                  errors={errors}
                />
                <LoginInput
                  register={register}
                  name='confirmPassword'
                  type='password'
                  label='Confirm Password'
                  errors={errors}
                />
                <CustomButton
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{
                    mt: '60px',
                    mb: 1,
                    width: '273px',
                    height: '60px',
                    fontFamily: 'satoshi-black',
                    fontSize: '20px',
                    textTransform: 'none'
                  }}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Submit
                </CustomButton>
              </form>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={6}
          className={classes.loginRoot}
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            padding: '0px',
            paddingLeft: '10%',
            color: '#242A31',
            boxShadow: '0px 0px 4px 0px #B5B7BF',
          }}
        >
          <Box sx={{ width: '560px', padding: 0 }}>
            <Typography
              sx={{
                fontFamily: 'satoshi-medium',
                fontSize: '20px'
              }}
            >
              WELCOME TO
            </Typography>
            <Typography
              variant='h4'
              sx={{
                fontSize: '60px',
                fontFamily: 'satoshi-black',
                marginTop: '10px'
              }}
            >
              Remote Operations {'\n'} Virtual Assistant
            </Typography>
            <Divider
              sx={{
                mt: 7,
                mb: 4,
                mr: 3,
                backgroundColor: 'black',
                width: '50%'
              }}
            />
            <Typography
              sx={{
                fontFamily: 'satoshi-bold',
                fontSize: '22px'
              }}
            >
              Reset your password to access Dashboard
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ForgotResetPassword
