import { createSlice } from '@reduxjs/toolkit'

const chatSlice = createSlice({
  name: 'chat',
  initialState: { selectedChat: null, chatList: [] },
  reducers: {
    setSelectedChat: (state, { payload }) => {
      state.selectedChat = payload?.selectedChat
    },
    setChatList (state, { payload }) {
      // if (payload.isReplace) {
      //   state.chatList = payload.chatList
      // } else {
      const existingChat = state.chatList.find(
        chat => chat.jobId === payload.chat.jobId
      )
      if (existingChat) {
        existingChat.lastMessage = payload.chat.lastMessage
        existingChat.unreadCount = payload.chat.unreadCount
          ? payload.chat.unreadCount
          : existingChat.unreadCount
        existingChat.unreadAlertCount = payload.chat.unreadAlertCount
          ? payload.chat.unreadAlertCount
          : existingChat.unreadAlertCount
        existingChat.updatedAt = payload.chat.updatedAt
        existingChat.job = payload.chat.job
          ? payload.chat.job
          : existingChat.job
        existingChat.visible = payload.chat.visible
      } else {
        state.chatList.push(payload.chat)
      }
      state?.chatList?.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      })
      // }
    },
    filterChatList (state, { payload }) {
      state.chatList = state?.chatList?.map(chat => {
        if (payload.jobIds.includes(chat.jobId)) {
          chat.visible = true
        } else {
          chat.visible = false
        }
        return chat
      })
    },
    incrementUnreadCount (state, { payload }) {
      const existingChat = state.chatList.find(
        chat => chat.jobId === payload.jobId
      )
      if (existingChat) {
        existingChat.unreadCount = existingChat.unreadCount + 1
      }
    },
    incrementUnreadAlertCount (state, { payload }) {
      const existingChat = state.chatList.find(
        chat => chat.jobId === payload.jobId
      )
      if (existingChat) {
        existingChat.unreadAlertCount = existingChat.unreadAlertCount + 1
      }
    },
    resetUnreadCount (state, { payload }) {
      const existingChat = state.chatList.find(
        chat => chat.jobId === payload.jobId
      )
      if (existingChat) {
        existingChat.unreadCount = 0
      }
    },
    resetUnreadAlertCount (state, { payload }) {
      const existingChat = state.chatList.find(
        chat => chat.jobId === payload.jobId
      )
      if (existingChat) {
        existingChat.unreadAlertCount = 0
      }
    }
  },
  extraReducers: builder => {}
})

export const {
  setSelectedChat,
  setChatList,
  filterChatList,
  incrementUnreadCount,
  incrementUnreadAlertCount,
  resetUnreadCount,
  resetUnreadAlertCount
} = chatSlice.actions

export default chatSlice.reducer
