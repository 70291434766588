import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const fieldsAPI = createApi({
  reducerPath: "fieldsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Field"],
  /**
   * Generates the endpoints for the builder.
   *
   * @param {function} builder - The builder function.
   * @return {object} The endpoints object.
   */
  endpoints: (builder) => ({
    fields: builder.query({
      /**
       * Fetches the fields.
       */
      query: () => "/template/Fields",
      providesTags: ["Field"],
    }),
    field: builder.query({
      /**
       * Fetches a single field by its id.
       */
      query: (id) => `/template/Fields/${id}`,
      providesTags: ["Field"],
    }),
    addField: builder.mutation({
      /**
       * Creates a new template field.
       *
       * @param {type} field - The field data to be included in the query.
       * @return {Object} - The generated query object.
       */
      query: (field) => ({
        url: "/template/Fields",
        method: "POST",
        body: field,
      }),
      invalidatesTags: ["Field"],
    }),
    updateField: builder.mutation({
      /**
       * Updates a template field by its id.
       *
       * @param {object} id - The ID of the template field.
       * @param {...object} rest - Additional parameters for the query.
       * @return {object} - The query object for updating template fields.
       */
      query: ({ id, ...rest }) => ({
        url: `/template/Fields/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Field"],
    }),
    deleteField: builder.mutation({
      /**
       * Deletes a template field by its id.
       *
       * @param {type} id - the id of the template field to delete
       * @return {type} the result of the deletion
       */
      query: (id) => ({
        url: `/template/Fields/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Field"],
    }),
  }),
});

export const {
  useFieldsQuery,
  useFieldQuery,
  useAddFieldMutation,
  useUpdateFieldMutation,
  useDeleteFieldMutation,
} = fieldsAPI;
