import { createSlice } from "@reduxjs/toolkit";
import { checklistAPI } from "../Services/Checklist";

const ChecklistSlice = createSlice({
  name: "checklist",
  initialState: { checklists: [] }, // Initial state
  reducers: {
    /**
     * Sets the checklists in the state.
     *
     * @param {Object} state - The current state object.
     * @param {Object} payload - The payload object containing the data.
     * @param {Array} payload.data - The array of checklists to be set.
     */
    setChecklists: (state, { payload }) => {
      state.checklists = payload?.data;
    },
  },
  /**
   * Adds an extra reducer to the Redux store.
   *
   * @param {function} builder - The reducer builder.
   * @return {void}
   */
  extraReducers: (builder) => {
    builder.addMatcher(
      checklistAPI.endpoints.checklists.matchFulfilled,
      (state, { payload }) => {
        state.checklists = payload?.data;
      }
    );
  },
});

export const { setChecklists } = ChecklistSlice.actions;

export default ChecklistSlice.reducer;
