import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { routes } from './rourets'
import { Container } from '@mui/system'
import CustomToast from '../components/CustomToast'
import { useDispatch, useSelector } from 'react-redux'
import LogIn from './Auth/login'
import { setCredentials } from '../Reducers/AuthSlice'
import ResetPassword from './Auth/resetPassword'
import ForgotPassword from './Auth/forgotPassword'
import ForgotResetPassword from './Auth/forgotResetPassword'
import CustomNotification from '../components/CustomNotification'

const PrivateRoutes = React.lazy(() => import('./protectedRoutes'))

const MainComponent = () => {
  const { severity, message } = useSelector(state => state.toastSlice)
  const notification = useSelector(state => state.notificationSlice)
  const dispatch = useDispatch()
  const auth = useSelector(state => state.authSlice)

  const checkIfUser = () => {
    const user = localStorage.getItem('user')
    const token = localStorage.getItem('token')
    if (user || token) {
      dispatch(
        setCredentials({
          user: JSON.parse(user),
          token: token
        })
      )
    }
  }

  useEffect(() => {
    checkIfUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <Router>
        <React.Suspense
          fallback={
            <Container
              sx={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </Container>
          }
        >
          <Routes>
            <Route exact path='/login' name='Login Page' element={<LogIn />} />
            <Route
              exact
              path='/forgotPassword'
              name='Forgot Password'
              element={<ForgotPassword />}
            />
            <Route
              exact
              path='/forgotPassword/reset/:id'
              name='Forgot Reset Password'
              element={<ForgotResetPassword />}
            />
            <Route
              exact
              path='/resetPassword'
              name='Reset Password'
              element={<ResetPassword />}
            />

            <Route path='/' element={<Navigate to={'/dashboard'} replace />} />
            <Route path='/' element={<PrivateRoutes />}>
              {auth &&
                auth?.user &&
                auth?.user?.role &&
                routes[auth?.user?.role]?.map((route, i) => (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.component />}
                  />
                ))}
              <Route
                path='*'
                element={
                  !auth?.user ? (
                    <Navigate to={'/login'} replace />
                  ) :
                  routes[auth?.user?.role]?.some(route =>
                      window.location.pathname.startsWith(route.path)
                    ) ? null : (
                    <Navigate to={'/dashboard'} replace />
                  )
                }
              />
            </Route>
          </Routes>
          {severity !== null && message !== null && (
            <CustomToast
              severity={severity}
              message={message}
              vertical='bottom'
              horizontal='center'
            />
          )}
          {notification?.severity !== null &&
            notification?.message !== null && (
              <CustomNotification
                severity={notification.severity}
                message={notification.message}
                vertical='bottom'
                horizontal='right'
              />
            )}
        </React.Suspense>
      </Router>
    </React.Fragment>
  )
}

export default MainComponent
