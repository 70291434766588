import { createSlice } from "@reduxjs/toolkit";

const tabInitialData = [
  {
    label: "Template",
    disabled: false,
  },
  {
    label: "Playbook",
    disabled: true,
  },
  {
    label: "Workflow",
    disabled: false,
  },
  {
    label: "Users",
    disabled: false,
  },
];

const settingsTabSlice = createSlice({
  name: "toast",
  initialState: { settingsTabsData: [...tabInitialData] },
  reducers: {
    setSettingsTabsData: (state, { payload }) => {
      state.settingsTabsData = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setSettingsTabsData } = settingsTabSlice.actions;

export default settingsTabSlice.reducer;
