import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const historyAPI = createApi({
  reducerPath: "historyAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Job"],
  endpoints: (builder) => ({
    history: builder.query({
      query: (jobId) => `/history?jobId=${jobId}`,
      providesTags: ["Job"],
    }),
  }),
});

export const { useHistoryQuery } = historyAPI;
