import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const bitsAPI = createApi({
  reducerPath: "bitsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Bit"],
  endpoints: (builder) => ({
    bits: builder.query({
      /**
       * Retrieves a list of bit types.
       *
       * @param {string} filter - Optional filter parameter.
       * @returns {string} The query string for retrieving bit types.
       */
      query: (filter) =>
        `${filter ? `/template/bitTypes?${filter}` : "/template/bitTypes"}`,
      providesTags: ["Bit"],
    }),
    bit: builder.query({
      /**
       * Retrieves a specific bit type by ID.
       *
       * @param {string} id - The ID of the bit type.
       * @returns {string} The query string for retrieving the bit type.
       */
      query: (id) => `/template/bitTypes/${id}`,
      providesTags: ["Bit"],
    }),
    addBit: builder.mutation({
      /**
       * Adds a new bit type.
       *
       * @param {Object} field - The field data for the new bit type.
       * @returns {Object} The mutation query for adding the bit type.
       */
      query: (field) => ({
        url: "/template/bitTypes",
        method: "POST",
        body: field,
      }),
      invalidatesTags: ["Bit"],
    }),
    updateBit: builder.mutation({
      /**
       * Updates an existing bit type by ID.
       *
       * @param {string} id - The ID of the bit type to update.
       * @param {Object} rest - The updated field data.
       * @returns {Object} The mutation query for updating the bit type.
       */
      query: ({ id, ...rest }) => ({
        url: `/template/bitTypes/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Bit"],
    }),
    deleteBit: builder.mutation({
      /**
       * Deletes a bit type by ID.
       *
       * @param {string} id - The ID of the bit type to delete.
       * @returns {Object} The mutation query for deleting the bit type.
       */
      query: (id) => ({
        url: `/template/bitTypes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Bit"],
    }),
  }),
});

export const {
  useBitsQuery,
  useBitQuery,
  useAddBitMutation,
  useUpdateBitMutation,
  useDeleteBitMutation,
} = bitsAPI;
