import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const modulesAPI = createApi({
  reducerPath: "modulesAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Modules"],
  endpoints: (builder) => ({
    modules: builder.query({
      query: () => "/chat-modules",
      providesTags: ["Modules"],
    }),
    module: builder.query({
      query: ({ moduleId, regionId }) =>
        `/child/findAllModulesTreeStructure?moduleId=${moduleId}&regionId=${regionId}`,
      providesTags: ["Modules"],
    }),
    phasesAndModules: builder.query({
      query: () => `/phase/modules`,
      providesTags: ["Modules"],
    }),
    addModules: builder.mutation({
      query: (body) => ({
        url: "/child/excelExtraction",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Modules"],
    }),
  }),
});

export const {
  useModulesQuery,
  useModuleQuery,
  useAddModulesMutation,
  usePhasesAndModulesQuery,
} = modulesAPI;
