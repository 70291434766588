import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const phaseAPI = createApi({
  reducerPath: "phaseAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Phase"],
  endpoints: (builder) => ({
    phases: builder.query({
      query: (jobId) => `/phase`,
      providesTags: ["Phase"],
    }),
  }),
});

export const { usePhasesQuery } = phaseAPI;
