import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const wellDetailsAPI = createApi({
  reducerPath: "wellDetailsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["WellDetails"],
  /**
   * Generates the endpoints for the given builder.
   *
   * @param {object} builder - The builder object.
   * @return {object} The generated endpoints.
   */
  endpoints: (builder) => ({
    allWellDetails: builder.query({
      /**
       * Get well details
       * @returns {string}
       */
      query: () => "/job/WellDetail",
      providesTags: ["WellDetails"],
    }),
    wellDetails: builder.query({
      /**
       * Get well details by id
       * @param {string} id
       * @returns {string}
       * */
      query: (id) => `/job/WellDetail/${id}`,
      providesTags: ["WellDetails"],
    }),
    addWellDetails: builder.mutation({
      /**
       * Creates a new well details
       * @param {object} job
       * @return {Object}
       * */
      query: (job) => ({
        url: "/job/WellDetail",
        method: "POST",
        body: job,
      }),
      invalidatesTags: ["WellDetails"],
    }),
    updateWellDetails: builder.mutation({
      /**
       * Updates a well details
       * @param {string} id
       * @param {object} job
       * @return {Object}
       */
      query: ({ id, ...rest }) => ({
        url: `/job/WellDetail/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["WellDetails"],
    }),
    deleteWellDetails: builder.mutation({
      /**
       * Deletes a well details
       * @param {string} id
       * @return {Object}
       */
      query: (id) => ({
        url: `/job/WellDetail/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["WellDetails"],
    }),
  }),
});

export const {
  useAllWellDetailsQuery,
  useWellDetailsQuery,
  useAddWellDetailsMutation,
  useUpdateWellDetailsMutation,
  useDeleteWellDetailsMutation,
} = wellDetailsAPI;
