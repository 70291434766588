import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const kpiAPI = createApi({
  reducerPath: "kpiApi",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Kpi"],
  /**
   * Generates the endpoints for the given builder.
   *
   * @param {object} builder - The builder object.
   * @return {object} The generated endpoints.
   */
  endpoints: (builder) => ({
    kpis: builder.query({
      /**
       * Generates a query string for the job endpoint based on the provided parameters.
       * @returns {string} The generated query string.
       */
      query: () => `/job/kpi`,
      providesTags: ["Kpi"],
    }),
    kpi: builder.query({
      /**
       * Fetches a single kpi by its id.
       */
      query: (id) => `/job/kpi/${id}`,
      providesTags: ["Kpi"],
    }),
    addKpi: builder.mutation({
      /**
       * Generates a query object for a given kpi.
       *
       * @param {Object} Kpi - The kpi object.
       * @return {Object} The query object.
       */
      query: (kpi) => ({
        url: "/job/kpi",
        method: "POST",
        body: kpi,
      }),
      invalidatesTags: ["Kpi"],
    }),
    updateKpi: builder.mutation({
      /**
       * Generates a query object for a given kpi.
       *
       * @param {Object} Kpi - The kpi object.
       * @return {Object} The query object.
       */
      query: ({ id, ...rest }) => ({
        url: `/job/kpi/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Kpi"],
    }),
    deleteKpi: builder.mutation({
      /**
       * Generates a query object for a given kpi.
       *
       * @param {Object} Kpi - The kpi object.
       * @return {Object} The query object.
       */
      query: (id) => ({
        url: `/job/kpi/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Kpi"],
    }),
  }),
});

export const {
  useKpisQuery,
  useKpiQuery,
  useAddKpiMutation,
  useUpdateKpiMutation,
  useDeleteKpiMutation,
} = kpiAPI;
