import { createSlice } from '@reduxjs/toolkit'

const BHADetailsSlice = createSlice({
  name: 'bhaDetails',
  initialState: {
    sectionId: null,
    bhas: [
      {
        bhaName: '',
        bhaNumber: 1,
        bitType: '',
        tools: [],
        startDepth: '',
        endDepth: '',
        bhaCorrection: [],
        bhaStages: []
      }
    ],
    ManifestURL: ''
  },
  reducers: {
    setFullBHA: (state, { payload }) => {
      state.sectionId = payload?.sectionId
      state.bhas = payload?.bhas?.map((bha, index) => ({
        bhaName: `BHA ${bha.bhaNumber}`,
        bhaNumber: bha.bhaNumber,
        bitType: bha.bitType,
        tools: [...bha.tools],
        startDepth: bha.startDepth,
        endDepth: bha.endDepth,
        bhaCorrection: [...bha.bhaCorrection],
        bhaStages: [...bha.bhaStages]
      }))
    },
    // addTools: (state, { payload }) => {
    //   state.bhas[payload.index].tools.push(payload.tool)
    // },
    // removeTools: (state, { payload }) => {
    //   state.bhas[payload.index].tools.splice(payload.toolIndex, 1)
    // },
    // addBHA: (state, { payload }) => {
    //   state.bhas.push(payload.bha)
    // },
    // removeBHA: (state, { payload }) => {
    //   state.bhas.splice(payload.bhaIndex, 1)
    // },
    // setBHAName: (state, { payload }) => {
    //   state.bhas[payload.index].bhaName = payload.bhaName
    // },
    // setBitType: (state, { payload }) => {
    //   state.bhas[payload.index].bitType = payload.bitType
    // },
    // setManifestURL: (state, { payload }) => {
    //   state.ManifestURL = payload?.ManifestURL
    // },
    // setStartDepth: (state, { payload }) => {
    //   state.bhas[payload.index].startDepth = payload.startDepth
    // },
    // setEndDepth: (state, { payload }) => {
    //   state.bhas[payload.index].endDepth = payload.endDepth
    // }
  },
  // extraReducers: builder => {}
})

export const {
  setFullBHA,
  addTools,
  removeTools,
  addBHA,
  removeBHA,
  setBHAName,
  setBitType,
  setManifestURL,
  setStartDepth,
  setEndDepth
} = BHADetailsSlice.actions

export default BHADetailsSlice.reducer
