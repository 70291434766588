import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Divider, Fade, IconButton, Typography } from '@mui/material'
import { SmallCloseIcon } from './CustomIcon'

/**
 * Managed Transitions of the dialog.
 */
const Transition = React.forwardRef(function Transition (props, ref) {
  return <Fade direction='down' ref={ref} {...props} />
})

/**
 * Renders a custom dialog component.
 *
 * @param {Object} props - The properties of the component.
 * @param {boolean} props.open - Controls whether the dialog is open or closed.
 * @param {function} props.handleClose - Callback function to handle closing the dialog.
 * @param {string} props.title - The title of the dialog.
 * @param {string} props.subtitle - The subtitle of the dialog.
 * @param {ReactNode} props.children - The content of the dialog.
 * @param {ReactNode} props.actionChildren - The additional actions of the dialog.
 * @param {...any} props - Additional properties to be spread on the Dialog component.
 * @return {ReactNode} - The rendered custom dialog component.
 */
const CustomDialog = ({
  open,
  handleClose,
  title,
  subtitle,
  children,
  actionChildren,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          //do nothing
        } else {
          handleClose()
        }
      }}
      disableEscapeKeyDown
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          minWidth: '634px !important',
          height: 'fit-content !important',
          maxHeight: '92% !important',
          border: '1px solid rgba(0,0,0,.2)'
        }
      }}
      {...props}
    >
      {/* Render the dialog title and subtitle */}
      <DialogActions
        style={{
          padding: '16px 24px 15px 28px',
          justifyContent: 'space-between'
        }}
      >
        <DialogTitle
          gridColumn={2}
          sx={{ fontSize: 24, fontFamily: 'satoshi-black', color: '#31333C' }}
        >
          {title}
          {subtitle && (
            <Typography fontSize={18} variant='body2' fontWeight={500}>
              {subtitle}
            </Typography>
          )}
        </DialogTitle>

        {/* Render the close button */}
        <IconButton onClick={handleClose}>
          <SmallCloseIcon sx={{ width: 18, height: 18, fontWeight: 'bold',fill:'#242A31' }} />
        </IconButton>
      </DialogActions>
      <Divider sx={{ backgroundColor: '#DEE1E3' }} />
      {/* Render the dialog content */}
      <DialogContent style={{ padding: '20px 52px' }}>{children}</DialogContent>

      {/* Render the additional action buttons */}
      {actionChildren && (
        <>
          <DialogActions
            style={{
              padding: '10px 32px 25px 32px',
              overflowX: 'hidden'
            }}
          >
            {actionChildren}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default CustomDialog
