import { createSlice } from "@reduxjs/toolkit";

const jobSlice = createSlice({
  name: "job",
  initialState: {
    jobId: null,
    selectedSections: [],
    selectedForbha: null,
    bhaDetails: {},
    reviewJobId: null,
    editJobId: null,
    rigDetails: null,
    wellDetails: null,
    sectionDetails: null,
    bhas: null,
    bhaNumber: 0,
  },
  reducers: {
    setJobId : (state, { payload }) => {
      state.jobId = payload;
    },
    setRigDetails: (state, { payload }) => {
      state.rigDetails = payload.rigDetails;
    },
    setWellDetails: (state, { payload }) => {
      state.wellDetails = payload.wellDetails;
    },
    setSectionDetails: (state, { payload }) => {
      state.selectedSections = payload.selectedSections;
    },
    setSelectedForBha: (state, { payload }) => {
      state.selectedForbha = payload.selectedForbha;
    },
    setBhaDetails: (state, { payload }) => {
      state.bhaDetails = payload.bhas;
    },
    setReviewJobId: (state, { payload }) => {
      state.reviewJobId = payload.reviewJobId;
    },
    setEditJobId: (state, { payload }) => {
      state.editJobId = payload.editJobId;
    },
    setJobDetails: (state, { payload }) => {
      state.rigDetails = payload.rigDetails;
      state.wellDetails = payload.wellDetails;
      state.sectionDetails = payload.sectionDetails;
      state.bhas = payload.bhas;
    },
    setBHANumber: (state, { payload }) => {
      state.bhaNumber = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setJobId,
  setRigDetails,
  setWellDetails,
  setSectionDetails,
  setBhaDetails,
  setSelectedForBha,
  setReviewJobId,
  setEditJobId,
  setJobDetails,
  setBHANumber,
} = jobSlice.actions;

export default jobSlice.reducer;
