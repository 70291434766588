import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'

/**
 * Styles for the custom button component
 *
 * @returns {Object} - styles for the custom button component
 */
const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '5px!important',
    fontFamily: 'satoshi-bold',
    fontSize: '20px!important',
    height: '50px',
    textTransform: 'none',
    letterSpacing: 1,
    '&:disabled': {
      backgroundColor: '#A5B8FF',
      color: '#fff'
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&:focus': {
      border: `1px solid ${theme.palette.secondary.main}`,
      boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)'
    }
  },
  outlinedButton: {
    borderRadius: '5px!important',
    fontFamily: 'satoshi-bold',
    fontSize: '20px!important',
    width: '150px!important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.contrastText,
      borderColor: 'black',
      color: 'black'
    },
    '&:focus': {
      border: `1px solid ${theme.palette.secondary.main}`,
      boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)'
    }
  }
}))

/**
 * Renders a custom button component with additional props.
 *
 * @param {Object} props - The props for the button component.
 * @param {ReactNode} props.children - The content of the button.
 * @param {string} props.color - The color of the button.
 * @param {string} props.variant - The variant of the button.
 * @return {ReactElement} The rendered custom button component.
 */
const CustomButton = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <LoadingButton
      disableElevation
      disableFocusRipple
      disableRipple
      disableTouchRipple
      variant='contained'
      color='secondary'
      className={
        props.color === 'secondary'
          ? props.variant === 'outlined'
            ? classes.outlinedButton
            : classes.button
          : ''
      }
      {...props}
      style={{
        letterSpacing: '0.8px',
        borderRadius: '5px',
      }}
    >
      {children}
    </LoadingButton>
  )
}

export default CustomButton
