import { createSlice } from "@reduxjs/toolkit";
import { authAPI } from "../Services/Auth";

const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, token: null },
  reducers: {
    setCredentials: (state, { payload: { user, token } }) => {
      state.user = user;
      state.token = token;
    },
    setAuthToken: (state, { payload: { token } }) => {
      state.token = token;
    },
    loggedOut: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authAPI.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.user = payload?.data;
        state.token = payload?.data?.token;
      }
    );
  },
});

//MIDDLEWARE
export const authSliceMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    if (getState().authSlice.token !== null) {
      localStorage.setItem("token", getState().authSlice.token);
      localStorage.setItem("user", JSON.stringify(getState().authSlice.user));
    }
    return result;
  };
};

export const { setCredentials, setAuthToken, loggedOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.authSlice.user;
export const fetchToken = ({ getState }) => {
  return getState().authSlice.token;
};
