import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const triggerTypesAPI = createApi({
  reducerPath: "triggerTypesAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["TriggerType"],
  /**
   * Generates the endpoints for the builder.
   *
   * @param {function} builder - The builder function.
   * @return {object} The generated endpoints.
   */
  endpoints: (builder) => ({
    /**
     * Fetches the trigger types
     * @return {string}
     * @tags TriggerType
     */
    triggerTypes: builder.query({
      query: () => "/meta/triggerType",
      providesTags: ["TriggerType"],
    }),
  }),
});

export const { useTriggerTypesQuery } = triggerTypesAPI;
