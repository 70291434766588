import { createTheme } from "@mui/material";
import satoshiRegular from "../assets/fonts/Satoshi-Regular.otf";
import satoshiBold from "../assets/fonts/Satoshi-Bold.otf";
import satoshiMedium from "../assets/fonts/Satoshi-Medium.otf";
import satoshiLight from "../assets/fonts/Satoshi-Light.otf";
import satoshiBlack from "../assets/fonts/Satoshi-Black.otf";
import avenirNextItalic from "../assets/fonts/AvenirNext-Italic.otf";

/**
 * Theme for the application
 */
export const theme = createTheme({
  palette: {
    primary: {
      main: "#242A31",
      contrastText: "#fff",
    },
    secondary: {
      main: "#2E5BFF",
      contrastText: "#fff",
    },
    background: {
      default: "#F8F9FA",
    },
    text: {
      icon: "#686873",
    },
    success: {
      main: "#379D00",
      light: "#379D00",
      dark: "#379D00",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["satoshi-regular", "satoshi-bold", "satoshi-medium","satoshi-light"].join(","),
    h1: {
      fontFamily: "satoshi-bold",
    },
    h2: {
      fontFamily: "satoshi-bold",
    },
    h3: {
      fontFamily: "satoshi-bold",
    },
    h4: {
      fontFamily: "satoshi-bold",
    },
    h5: {
      fontFamily: "satoshi-bold",
    },
    h6: {
      fontFamily: "satoshi-bold",
    },
    body1: {
      fontFamily: "satoshi-medium",
      fontSize: 12,
    },
    body2: {
      fontFamily: "satoshi-regular",
    },
    subtitle1: {
      fontFamily: "satoshi-medium",
      color: "#6F6E74",
      fontSize: 12,
    },
    subtitle2: {
      fontFamily: "satoshi-regular",
      color: "#6F6E74",
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'satoshi-regular';
        src: url(${satoshiRegular}) format("truetype");
        font-weight: normal;
      }
      @font-face {
        font-family: 'satoshi-bold';
        src: url(${satoshiBold}) format("truetype");
        font-weight: normal;
      }
      @font-face {
        font-family: 'satoshi-black';
        src: url(${satoshiBlack}) format("truetype");
        font-weight: normal;
      }
      @font-face {
        font-family: 'satoshi-medium';
        src: url(${satoshiMedium}) format("truetype");
        font-weight: normal;
      }
      @font-face {
        font-family: 'satoshi-light';
        src: url(${satoshiLight}) format("truetype");
        font-weight: normal;
      }
      @font-face {
        font-family: 'avenir-next-italic';
        src: url(${avenirNextItalic}) format("truetype");
        font-weight: normal;
      }
      `,
    },
  },
});
