import React from "react";
import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import MainComponent from "./pages/main";
import { theme } from "./styles/theme";
import { Provider } from "react-redux";
import { store } from "./Store";

function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MainComponent />
        </ThemeProvider>
      </Provider>
    </React.Fragment>
  );
}

export default App;
