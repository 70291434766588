import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const authAPI = createApi({
  reducerPath: "authAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: credentials,
        invalidatesTags:["Auth"],
      }),
    }),
  }),
});

export const { useLoginMutation } = authAPI;
