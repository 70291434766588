import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const extractChecklistAPI = createApi({
  reducerPath: "extractChecklistAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["ExtractChecklist"],
  /**
   * Generates the endpoints for the given builder object.
   *
   * @param {object} builder - The builder object.
   * @return {object} The endpoints object.
   */
  endpoints: (builder) => ({
    extractChecklist: builder.mutation({
      /**
       * Creates a new checklist.
       * @return {object}
       * @invalidates Checklist
       */
      query: (data) => ({
        url: "/aws-apis/extractChecklist",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ExtractChecklist"],
    }),
  }),
});

export const {
  useExtractChecklistMutation,
} = extractChecklistAPI;
