import * as React from 'react'
import { setToast } from '../../Reducers/ToastSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { forgotPasswordValidation } from '../../pages/Auth/validations/forgotPassword.validation'
import axios from 'axios'
import LoginInput from './LoginInput'
import CustomButton from '../CustomButton'
import CustomDialog from '../CustomDialog'

const BASE_URL = process.env.REACT_APP_API_URL

const ForgotPasswordDialog = ({
  open,
  handleClose,
  title,
  subtitle,
  children,
  actionChildren,
  handleSuccess,
  ...props
}) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(forgotPasswordValidation)
  })

  const onSubmit = async data => {
    try {
      setIsLoading(true)
      const path = BASE_URL + `/users/forgotPassword`
      const resetting = await axios.post(path, data)

      if (resetting?.data?.success) {
        handleSuccess(true)
        dispatch(
          setToast({
            severity: 'success',
            message: resetting?.data?.message,
            open: true
          })
        )
        setIsLoading(false)
        handleClose()
      } else {
        setIsLoading(false)
        dispatch(
          setToast({
            severity: 'error',
            message: resetting?.data?.response,
            open: true
          })
        )
      }
    } catch (error) {
      setIsLoading(false)
      setError('email', {
        type: 'manual',
        message: error?.response?.data?.message
      })
    }
  }
  return (
    <CustomDialog
      open={open}
      keepMounted
      handleClose={handleClose}
      disableEscapeKeyDown
      title={title}
      children={
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '-30px'
          }}
        >
          <LoginInput
            register={register}
            name='email'
            label='Email'
            errors={errors}
            sx={{
              '& .MuiInputBase-root': {
                width: '502px!important'
              }
            }}
          />
          <CustomButton
            type='submit'
            fullWidth
            variant='contained'
            sx={{
              mt: '75px',
              mb: '30px',
              width: '273px',
              height: '60px',
              fontFamily: 'satoshi-black',
              fontSize: '20px',
              textTransform: 'none'
            }}
            loading={isLoading}
            disabled={isLoading}
          >
            Submit
          </CustomButton>
        </form>
      }
      aria-describedby='alert-dialog-slide-description'
    />
  )
}

export default ForgotPasswordDialog
