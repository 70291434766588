import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const sectionDetailsAPI = createApi({
  reducerPath: "sectionDetailsAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["SectionDetails"],
  endpoints: (builder) => ({
    allSectionDetails: builder.query({
      query: () => "/job/section",
      providesTags: ["SectionDetails"],
    }),
    sectionDetails: builder.query({
      query: (id) => `/job/section/${id}`,
      providesTags: ["SectionDetails"],
    }),
    addSectionDetails: builder.mutation({
      query: (job) => ({
        url: "/job/section/bulkCreate",
        method: "POST",
        body: job,
      }),
      invalidatesTags: ["SectionDetails"],
    }),
    updateSectionDetails: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/job/section/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["SectionDetails"],
    }),
    deleteSectionDetails: builder.mutation({
      query: (id) => ({
        url: `/job/section/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SectionDetails"],
    }),
    updateRigupChecklist: builder.mutation({
      query: ({id, data}) => ({
        url: `/job/section/updateRigUpchecklist/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["SectionDetails"],
    }),
  }),
});

export const {
  useAllSectionDetailsQuery,
  useSectionDetailsQuery,
  useAddSectionDetailsMutation,
  useUpdateSectionDetailsMutation,
  useDeleteSectionDetailsMutation,
  useUpdateRigupChecklistMutation,
} = sectionDetailsAPI;
