import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBasseQuery } from "./BaseQuery";

export const proceduresAPI = createApi({
  reducerPath: "proceduresAPI",
  baseQuery: extendedBasseQuery,
  tagTypes: ["Procedure"],
  /**
   * Generates an object that defines various API endpoints.
   *
   * @param {object} builder - The API builder object.
   * @return {object} An object containing the defined endpoints.
   */
  endpoints: (builder) => ({
    procedures: builder.query({
      /**
       * Fetches the procedures list.
       * @return {string}
       * @tags Procedure
       */
      query: ({ type }) => `/playbook/procedure`,
      providesTags: ["Procedure"],
    }),
    procedure: builder.query({
      /**
       * Fetches a single procedure by its id.
       * @param {string} id - The ID of the procedure to retrieve.
       * @return {string} - The generated query string.
       * @tags Procedure
       */
      query: (id) => `/playbook/procedure/${id}`,
      providesTags: ["Procedure"],
    }),
    addProcedure: builder.mutation({
      /**
       * Creates a new procedure.
       * @return {object} - The query object.
       * @invalidates Procedure
       */
      query: (data) => ({
        url: "/playbook/procedure",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Procedure"],
    }),
    updateProcedure: builder.mutation({
      /**
       * Updates a procedure by ID.
       * @param {object} query - An object containing the id and additional properties.
       * @param {string} query.id - The id of the playbook to trigger.
       * @param {object} query.rest - Additional properties for the query.
       * @return {object} - The query object.
       * @invalidates Procedure
       */
      query: ({ id, ...rest }) => ({
        url: `/playbook/procedure/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Procedure"],
    }),
    deleteProcedure: builder.mutation({
      /**
       * Deletes a procedure by ID.
       * @param {string} id - The ID of the procedure to delete.
       * @return {object} - The query object.
       * @invalidates Procedure
       * */
      query: (id) => ({
        url: `/playbook/procedure/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Procedure"],
    }),
  }),
});

export const {
  useProceduresQuery,
  useProcedureQuery,
  useAddProcedureMutation,
  useUpdateProcedureMutation,
  useDeleteProcedureMutation,
} = proceduresAPI;
